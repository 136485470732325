import { combineReducers } from "redux";
import userReducers from "./userReducers";
import scriptReducers from "./scriptReducers";
import themeReducers from "./themeReducers";
import videoReducers from "./videoReducers";

export default combineReducers({
    user: userReducers,
    script: scriptReducers,
    theme: themeReducers,
    video: videoReducers,
})