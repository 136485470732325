import React from 'react';
import Layout from './containers/Layout';
import Login from './components/Login/Login';
import { Route, Switch } from 'react-router-dom';
import './stylesheets/css/font-awesome.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-toastify/dist/ReactToastify.css";
import "react-responsive-modal/styles.css";
import './App.css';


function App(props) {

  return (
    <Switch>
      <Route exact path="/login" name="Login Page" render={ props => <Login {...props}/> } />
      <Route path="/" name="Layout" render={ props => <Layout {...props}/> } />
    </Switch>
  );
}

export default App;
