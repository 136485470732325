import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { isMobile } from "react-device-detect";

class Sidebar extends Component {


    closeSidebar = () => {
        this.props.handleViewSideBar()
    }
    render() {

        return (
            <ul className={`navbar-nav sidebar sidebar-light accordion ${!this.props.sidebarOpen ? 'sidebarOpen' : 'sidebarClose'}`} id="accordionSidebar" >
                <a className="sidebar-brand d-flex align-items-center justify-content-center" href="/" onClick={() => isMobile ? this.closeSidebar() : null}>
                    <div className="sidebar-brand-text mx-3">CV Global</div>
                </a>
                <hr className="sidebar-divider my-0" />
                <li className="nav-item active">
                    <a className="nav-link" href="/" onClick={() => isMobile ? this.closeSidebar() : null}>
                        <i className="fas fa-fw fa-tachometer-alt"></i>
                        <span>Dashboard</span></a>
                </li>
                <hr className="sidebar-divider" />
                <div className="sidebar-heading">
                    Users Management
                </div>
                <li className="nav-item">
                    <NavLink className="nav-link collapsed" to="#" data-toggle="collapse" data-target="#collapseUsersManagement"
                        aria-expanded="true" aria-controls="collapseUsersManagement">
                        <i className="fas fa-users"></i>
                        <span>Users</span>
                    </NavLink>
                    <div id="collapseUsersManagement" className="collapse" aria-labelledby="headingBootstrap" data-parent="#accordionSidebar">
                        <div className="bg-white py-2 collapse-inner rounded">
                            <Link className="collapse-item" to="/list-users" onClick={() => isMobile ? this.closeSidebar() : null}>List All</Link>
                        </div>
                    </div>
                </li>
                <hr className="sidebar-divider" />
                <div className="sidebar-heading">
                    Script Management
                </div>
                <li className="nav-item">
                    <NavLink className="nav-link collapsed" to="#" data-toggle="collapse" data-target="#collapseScriptManagement"
                        aria-expanded="true" aria-controls="collapseScriptManagement">
                        <i className="fas fa-laptop-code"></i>
                        <span>Scripts</span>
                    </NavLink>
                    <div id="collapseScriptManagement" className="collapse" aria-labelledby="headingBootstrap" data-parent="#accordionSidebar">
                        <div className="bg-white py-2 collapse-inner rounded">
                            <Link className="collapse-item" to="/list-scripts" onClick={() => isMobile ? this.closeSidebar() : null}>List All</Link>
                        </div>
                    </div>
                </li>
                <hr className="sidebar-divider" />
                <div className="sidebar-heading">
                    Theme Management
                </div>
                <li className="nav-item">
                    <NavLink className="nav-link collapsed" to="#" data-toggle="collapse" data-target="#collapseScriptManagementt"
                        aria-expanded="true" aria-controls="collapseScriptManagementt">
                        <i className="fas fa-laptop-code"></i>
                        <span>Themes</span>
                    </NavLink>
                    <div id="collapseScriptManagementt" className="collapse" aria-labelledby="headingBootstrap" data-parent="#accordionSidebar">
                        <div className="bg-white py-2 collapse-inner rounded">
                            <Link className="collapse-item" to="/list-themes" onClick={() => isMobile ? this.closeSidebar() : null}>List All</Link>
                        </div>
                    </div>
                </li>
                <hr className="sidebar-divider" />
                <div className="sidebar-heading">
                 Video Filename Mangement
                </div>
                <li className="nav-item">
                    <NavLink className="nav-link collapsed" to="#" data-toggle="collapse" data-target="#genrateFilename" aria-expanded="true" aria-controls="genrateFilename">
                        {/* <i class="fas fa-video"></i> */}
                        <i class="fas fa-file"></i>
                        <span>Genrate Filename</span>
                    </NavLink>
                    <div id="genrateFilename" className="collapse" aria-labelledby="headingBootstrap" data-parent="#accordionSidebar">
                        <div className="bg-white py-2 collapse-inner rounded">
                            <Link className={window.location.pathname === "/genrate-video-filename"? "selectedItem" : "collapse-item"} to="/genrate-video-filename" onClick={() => isMobile ? this.closeSidebar() : null}>Genrate Filename</Link>
                            <Link className={window.location.pathname === "/list-video-filenames"? "selectedItem" : "collapse-item"}  to="/list-video-filenames" onClick={() => isMobile ? this.closeSidebar() : null}>List All</Link>
                        </div>
                    </div>
                </li>
                <hr className="sidebar-divider" />
                <div className="sidebar-heading">
                    Video Management
                </div>
                <li className="nav-item">
                    <NavLink className="nav-link collapsed" to="#" data-toggle="collapse" data-target="#videoUpload" aria-expanded="true" aria-controls="videoUpload">
                        <i class="fas fa-video"></i>
                        <span>Video Upload</span>
                    </NavLink>
                    <div id="videoUpload" className="collapse" aria-labelledby="headingBootstrap" data-parent="#accordionSidebar">
                        <div className="bg-white py-2 collapse-inner rounded">
                            <Link className={window.location.pathname === "/video-upload"? "selectedItem" : "collapse-item"} to="/video-upload" onClick={() => isMobile ? this.closeSidebar() : null}>Video Upload</Link>
                            <Link className={window.location.pathname === "/video-list"? "selectedItem" : "collapse-item"}  to="/video-list" onClick={() => isMobile ? this.closeSidebar() : null}>List All</Link>
                        </div>
                    </div>
                </li>
            </ul>
        )
    }
}

export default Sidebar;