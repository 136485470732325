import React, { Component } from 'react';
import { Container, Row, Col, Button, Form,ProgressBar } from 'react-bootstrap';
import { toast } from'react-toastify';
import Dropzone from "react-dropzone";
import { addVideo } from '../../actions/videoActions';
import { connect } from 'react-redux';
import authHeader from '../../helpers/auth-header';
import axios from 'axios';
import { ENV } from '../../env';

toast.configure()

class UploadVideoComponent extends Component{
    constructor(props){
        super(props);
        this.state = {
            selectedMedia:[],
            mediaPreview:[],
            fileUploading:false,
            loader: false,
        }
    }

    handleDrop = async acceptedFiles => {
        let mediaFile = []
        let mediaPreview = []
        if(this.state.selectedMedia.length > 0){
            mediaFile = this.state.selectedMedia;
            mediaPreview = this.state.mediaPreview;
        }
        
        let allowedExtension = /video/gi;
        
        for(let i = 0; i < acceptedFiles.length; i++){
            let mFile = acceptedFiles[i]
            if(mFile.type.match(allowedExtension)){
                mediaFile.push(mFile)
                mediaPreview.push({
                    preview: URL.createObjectURL(mFile),
                    uploadPercent:0,
                    status:'waiting'
                })
            }
        }

        this.setState({ 
            showFileTypeError:'',
            selectedMedia: mediaFile,
            mediaPreview: mediaPreview
        })
    }

    handleVideoUpload = async (e) => {
        e.preventDefault();
        this.setState({ fileUploading: true })
        this.recurssionCall(this.state.selectedMedia, 0)
    }

    handleOnCompleteUpload = () => {
        this.setState({
            selectedMedia:[],
            mediaPreview:[],
            fileUploading:false,
            loader: false,
        })
    }

    recurssionCall =  async (mediaFiles, count) => {
        try {
            
            const options = {
                headers: { 
                    "Authorization": authHeader() 
                },
                onUploadProgress: (progressEvent) => {
                    var {loaded, total} = progressEvent;
                    var percent = Math.floor((loaded * 100) / total);
                    // console.log( 'File ::', mediaFiles[count], ' Uploading ---->>>>>> ', percent+'%' );
                    let mediaPreview = this.state.mediaPreview
                    // console.log( 'media Preview::', mediaPreview[count] )
                    mediaPreview[count].uploadPercent = percent
                    mediaPreview[count].status = 'processing'
                    this.setState({mediaPreview})
                }
            }
            const fromData = new FormData();
            fromData.append('footage', mediaFiles[count])
            const res = await axios.post(`${ENV.mainApi}`.concat('/footage'), fromData, options);
            // console.log( res.data, mediaFiles[count] )
            let mediaPreview = this.state.mediaPreview
            // console.log( res.data, mediaFiles[count], mediaPreview[count] )
            if( res.data && res.data.response ){
                mediaPreview[count].uploadPercent = 0
                mediaPreview[count].status = 'completed'
                this.setState({mediaPreview})
                // console.log(' Uploaded', res.data.message)
                count++;
                if(count === mediaFiles.length){
                    this.setState({ fileUploading: false })
                    this.handleOnCompleteUpload()
                    return ;
                }
                return this.recurssionCall(mediaFiles, count);
            }else{
                mediaPreview[count].uploadPercent = 0
                mediaPreview[count].status = 'failed'
                this.setState({mediaPreview})
                // console.log( ' Failed to Upload', res.data.message )
                count++;
                if(count === mediaFiles.length){
                    this.setState({ fileUploading: false })
                    return ;
                }
                return this.recurssionCall(mediaFiles, count);
            }
           
        } catch (error) {
            // console.log( ' Error ----->>> ', error );
            let mediaPreview = this.state.mediaPreview
            mediaPreview[count].uploadPercent = 0
            mediaPreview[count].status = 'failed'
            this.setState({mediaPreview})
            count++;
            if(count === mediaFiles.length){
                this.setState({ fileUploading: false })
                return ;
            }
            return this.recurssionCall(mediaFiles, count);
        }
   }

    // handleVideoUpload = async (e) =>{
    //     e.preventDefault();
    //     this.setState({ fileUploading : true , showFileTypeError: '', loader : true})
    //     const fileData = new FormData()
    //     try{
            // const options = {
            //     headers: { "Authorization":authHeader() },
            //     onUploadProgress: (progressEvent) => {
            //         var {loaded, total} = progressEvent;
            //         var percent = Math.floor((loaded * 100) / total);
            //         this.setState({
            //             :percent
            //         })
            //     }
            // }
            
    //         for (const file of this.state.selectedMedia) {
    //             fileData.append('footage', file);
    //         }

    //         const res = await axios.post(`${ENV.mainApi}` + '/footage', fileData, options)

    //         const result = res.data;
    //         if(result){
    //             if(result.response){
    //                 toast.dismiss()
    //                 toast.success(result.message, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
                    
    //                 setTimeout(()=>{
    //                     this.setState({
    //                         fileUploading:false,
    //                         uploadSuccess: true,
    //                         showFileTypeError:'',
    //                         selectedMedia:[],
    //                         mediaPreview:[],
    //                         loader:false
    //                     })
    //                 }, 800) 
    //             }else{
    //                 this.setState({
    //                     showFileTypeError:result.message,
    //                     fileUploading:false,
    //                     :null,
    //                     loader:false
    //                 })
    //             }
    //         }else{
    //             this.setState({
    //                 showFileTypeError:'Failed To Upload',
    //                 fileUploading:false,
    //                 :null,
    //                 loader:false
    //             })
    //         }
    //     }catch(error){
    //         this.setState({
    //             showFileTypeError:'Something went wrong',
    //             fileUploading:false,
    //             :null,
    //             loader:false
    //         })
    //     }
    // }

    handleDeleteVideo = async (mediaFile, preview) => {
        // this.setState({
        //     loader: false
        // })

        var {selectedMedia, mediaPreview } = this.state;
        selectedMedia = selectedMedia.filter(m => m.name !== mediaFile.name)
        mediaPreview = mediaPreview.filter(m => m.preview !== preview)

        this.setState({
            selectedMedia: selectedMedia,
            mediaPreview: mediaPreview,
            // loader:true
        })
    }

    render(){
        const { selectedMedia, mediaPreview, fileUploading } = this.state
        return(
            <div className="uploadForm px-sm-5">
                <Container fluid>
                    <Form>
                        <Row className="justify-content-center">
                            <Col sm={12}>
                                <div className="main_s">
                                    <div className="fileDrag">
                                        <label for="statusVideo">
                                            <Dropzone onDrop={this.handleDrop} >
                                                {({ getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject }) => {
                                                    const additionalClass = isDragAccept ? "accept" : isDragReject ? "reject" : '';  
                                                    return (
                                                        <div {...getRootProps({ className: `dropzone ${additionalClass}` })}>
                                                            <input {...getInputProps()} />
                                                            {isDragActive 
                                                                ?
                                                                <>
                                                                    <img src="../assets/img/drop.png" alt="..." />
                                                                    <h3>Drop Video here to Upload</h3>
                                                                </>
                                                                :
                                                                <>
                                                                    <img src="../assets/img/chapter.png" alt="..." />
                                                                    <h3>Drag Video anywhere to Upload</h3>
                                                                </>
                                                            }
                                                            <p className="my-4 text-uppercase">OR</p>
                                                            <h3>Click to Upload</h3>
                                                        </div>
                                                    )
                                                }}
                                            </Dropzone>
                                        </label>
                                    </div>
                                </div>
                            </Col>
                            {mediaPreview.length > 0
                                                ?
                                <Col sm={12}>
                                    <div className='text-center mt-5'>
                                        <h3>Video's To Upload</h3>

                                    </div>    
                                    <div className='upload_video'>
                                        <div className='row'>
                                            {selectedMedia.map((mediaFile, mediaIdx) => {
                                                return (
                                                    <div className="video_sec col-md-4">
                                                        <video className="col-md-12 main_videos" width="100%" src={mediaPreview[mediaIdx].preview} type={mediaFile.type} controls/>    
                                                        <div className='video_sec_content'>
                                                            <div className='preview_file_name'>
                                                                <span>{mediaFile.name}</span>  
                                                                <span className='ml-2'>{`(${(mediaFile.size / 1024 / 1024).toFixed(1)} MB)`}</span>
                                                            </div>
                                                           
                                                            {mediaPreview[mediaIdx].uploadPercent
                                                                ?   mediaPreview[mediaIdx].uploadPercent !== 100 
                                                                    ?   <ProgressBar 
                                                                            now={mediaPreview[mediaIdx].uploadPercent} 
                                                                            label={`${mediaPreview[mediaIdx].uploadPercent}%`}
                                                                            striped={true}
                                                                            animated={true}
                                                                        />
                                                                    :  <p className={`preview_file_status preview_status_${mediaPreview[mediaIdx].status}`}>{mediaPreview[mediaIdx].status}</p>
                                                                :  fileUploading ? <p className={`preview_file_status preview_status_${mediaPreview[mediaIdx].status}`}>{mediaPreview[mediaIdx].status}</p>: null
                                                            }   
                                                            {!fileUploading && 
                                                            <span 
                                                                className="trash_icon" 
                                                                onClick={() => this.handleDeleteVideo(mediaFile, mediaPreview[mediaIdx].preview)}
                                                            >
                                                                <i className="fa fa-trash" aria-hidden="true"/> 
                                                            </span>}
                                                        </div>
                                                    </div>
                                                )   
                                            })}
                                        </div>
                                    </div>
                                </Col>
                                :
                                null
                            }    
                            {(mediaPreview.length > 0)
                                ? <Button 
                                    variant="outline-primary" className="text-capitalize d-block" 
                                    onClick={(e) => this.handleVideoUpload(e)}
                                    disabled={fileUploading}
                                    >
                                        Submit
                                    </Button>
                                : null
                            }

                            {/* <Col sm={12} className="text-center">
                                {fileUploading 
                                    ?   
                                    <div className="text-center mt-3 mb-3 d-inline">

                                        <button type="submit" className="btn btn-primary" disabled={fileUploading }> 
                                                { this.state. < 100 ? `Uploading... ${this.state.} %` : `Uploaded ${this.state.uploadedInPercent} %` }
                                        </button>
                                        <Spinner className='ml-2' as="span" animation="border" size="sm" role="status" />
                                    </div>
                                    : null
                                }
                                
                            </Col> */}
                        </Row>
                    </Form>      
                </Container>
            </div>
        )
    }
}

const mapStateToProps = state => {
    const { user } = state
    return {
        users: user.listUsers
    }

}

const dispatch = { addVideo }
export default connect(mapStateToProps, dispatch)(UploadVideoComponent);
