import React, { Component } from 'react';
import { Table, Spinner,Container, Modal, Form, Button, Row, Col, OverlayTrigger, Tooltip } from'react-bootstrap';
import * as SwalReact from "@sweetalert/with-react";
import * as check from '../../helpers/validation';
import { getVideos, deleteVideo, updateVideo } from '../../actions/videoActions';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import swal from 'sweetalert';
import ReactPaginate from 'react-paginate';
toast.configure()

class VideoListComponent extends Component{
    constructor(props) {
        super(props)

        this.state = {
            videos: [],
            // themeDetail: {},
            errors: {},
            backupVideos: [],
            loader: false,
            updateLoader: false,
            page: 0,
            perPage: 10,
            searchInput: '',   
            editVideoModal:false,
            videoDetail:{},
            isFormValid:true

        }
    }

    componentDidMount = async () => {
        this.handleLoader('loader', true)
        await this.handleFetchVideos();
    }

    handleLoader = (name, value) => { this.setState({ [name]: value }) }

    handleFetchVideos = async () => {
        await this.props.getVideos();
        if (this.props.videos.success) {
            this.handleLoader('loader', false)
            this.setState({
                videos: this.props.videos.footages,
                backupVideos: this.props.videos.footages
            })
        } else {
            this.handleLoader('loader', false)
            toast.dismiss()
            toast.error(this.props.videos.message, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true })
        }
    }

    handleViewOnScript = (video) => {
        SwalReact({
            buttons: {
                cancel: "Close"
            },
             content: (
                 <div style={{padding:'0.25rem'}}>
                    <h2 className="text-left"> {video.media}</h2>
                    <video width="100%" height="100%" controls="true" autoPlay>
                      <source src={video.mediaUrl} type="video/mp4"/>
                    </video>
                 </div> 
             )
        })
    }

    handleViewOnScript = (video) => {
        SwalReact({
            buttons: {
                cancel: "Close"
            },
             content: (
                 <div style={{padding:'0.25rem'}}>
                    <h2 className="text-left"> {video.media}</h2>
                    <video width="100%" height="100%" controls="true" autoPlay>
                      <source src={video.mediaUrl} type="video/mp4"/>
                    </video>
                 </div> 
             )
        })
    }

    handleEditOnScript = () => {
        SwalReact({
            buttons: {
                cancel: "Close"
            },
             content: (
                <div class="fileDrag">
                    <video width="auto" height="250" controls="true" autoPlay>
                        <source src="../assets/Video/Typing_dark_03_Videvo.mp4" type="video/mp4"/>
                    </video>
                    <input type="file" autocomplete="off" tabindex="-1" hidden/>
                    <h3>Drag Video anywhere to Upload</h3><p class="my-4 text-uppercase">OR</p>
                    <Button type="button" variant="outline-primary" className="text-capitalize">Click to Upload</Button>
                    <Button type="button" variant="primary" className="text-capitalize ml-3">Save Changes</Button>
                </div>
             )
        })
    }

    handleSearchInput = (e) => {
        const { value } = e.target
        let videos = this.state.backupVideos;
        if (value.length > 0) {
            videos = videos.filter(video => {
                if (video['media'] !== undefined && video['uploadedBy'].fullname !== undefined) {
                    return video['media'].toLowerCase().match(value.toLowerCase()) || video['uploadedBy'].fullname.toLowerCase().match(value.toLowerCase())
                }
            })
            this.setState({ videos })
        } else {
            this.setState({ videos })
        }
        this.setState({ searchInput: value })
    }

    onChangeEntries = (e) => {
        const { value } = e.target
        let videos = this.state.backupVideos
        this.setState({ perPage: Number(value), page: 0, searchInput: '', videos })
    }

    handleDeleteVideo = async (id) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, it won't be revert",
            icon: "warning",
            buttons: ['Cancel', 'Yes'],
            dangerMode: true,
        }).then(async (willDelete) => {
            if (willDelete) {
                await this.props.deleteVideo(id)
                if (this.props.delVideo.success) {
                    this.handleFetchVideos();
                    swal(this.props.delVideo.message, { icon: "success" });
                } else {
                    swal(this.props.delVideo.message, { icon: "error" });
                }
            }
        });
    }

    handleCloseEditModal = async () => {
        this.setState({editVideoModal: false, videoDetail:{}})
        await this.handleFetchVideos()
    }

    handleRemoveTagOnDoubleClick = (tag) => {
        let { videoDetail } = this.state
        videoDetail['videoTags'] = videoDetail['videoTags'].filter(t => t !== tag)
        if(videoDetail['videoTags'].length < 1){
            this.setState({ isFormValid: false })
        }
        this.setState({videoDetail})
    }

    handleVideoUpdate = async (e) => {
        e.preventDefault()
        this.handleLoader('updateLoader', true);
        await this.props.updateVideo(this.state['videoDetail']._id, this.state.videoDetail);
        if(this.props.updVideo.success){
            this.handleLoader('updateLoader', false)
            toast.dismiss()
            toast.success( this.props.updVideo.message, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true })
            setTimeout(() => {
                this.handleCloseEditModal()
            }, 1200) 
        }else{
            this.handleLoader('updateLoader', false)
            toast.dismiss()
            toast.error( this.props.updVideo.message, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true })
        }
    }

    handleEditVideo = async (video) =>{
        if(video._id){
            this.setState({ videoDetail: video, 
            editVideoModal: true })
        }else{
            this.setState({ videoDetail: {}, 
                editVideoModal: true 
            })
        }
    }

    handleAddTag = (e) => {
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
            let errors = this.state.errors
            errors['videoTags'] = ''
            this.setState({ errors })
            if (this.inputTags.value.length < 1) return;

            if(this.inputTags.value.trim().length < 1) return;

            let videoDetail = this.state.videoDetail
            
            videoDetail['videoTags'] = videoDetail['videoTags'].slice() 
            let tagFound = videoDetail['videoTags'].find(t => t.toLowerCase() === this.inputTags.value.toLowerCase())
            if(tagFound){
                errors['videoTags'] = `Can't add duplicate tag`
                this.setState({ errors })
                return;
            }

            videoDetail['videoTags'].push(check.capitalizeFirstLetter(this.inputTags.value));
            errors['videoTags'] = ''

            // ----- Used till approve buttons are hidden ------ //
            if(videoDetail['videoTags'].length > 0){
                this.setState({ isFormValid: true })
            }
            // ----- Used till approve buttons are hidden ------ //

            this.setState({videoDetail, errors});
            this.inputTags.value = '';
        }, 750)
    }

    handleInput = (e) => {
        const { value, name } = e.target
        let videoDetail = this.state['videoDetail']
        let errors = this.state['errors'];
        videoDetail[name] = check.capitalizeFirstLetter(value)
        let isFieldValid = this.fieldValidation(name);
        let isFormValid = this.isVideoFormValid();
        if(isFieldValid){ 
            errors[name] = ""
        }
        this.setState({ videoDetail, isFormValid, errors })
    }

    fieldValidation = (name) => {
        const { videoDetail } = this.state
        let errors = this.state.errors
        let isValid = true
        if(name === 'media'){
            if(!videoDetail['media']){
                isValid = false
                errors['media'] = "Can't be blank"
            }
            if(videoDetail['media'] !== undefined ){   
                if(videoDetail['media'].trim().length < 1){
                    isValid = false
                    errors['media'] = "Can't be blank"
                }
            }
        }       

        this.setState({ errors })
        return isValid
    }

    isVideoFormValid = () => {
        const { videoDetail } = this.state
        let isValid = true

        if(!videoDetail['media']){
            isValid = false
        }

        if(videoDetail['media'] !== undefined ){
            if(videoDetail['media'].trim().length < 1){
                isValid = false
            }
            if(videoDetail['media'].length < 3){
                isValid = false
            }
        }

        if(videoDetail['tags'] !== undefined && videoDetail['tags'].length < 1){
            isValid = false
        }

        return isValid
    }

    render(){
        const { videos, loader, page, perPage, searchInput, refreshLoading, videoDetail, editVideoModal, errors, updateLoader, isFormValid } = this.state
        const pageNumbers = Math.ceil(videos.length / perPage);
        const offset = page * perPage;
        const currentResults = videos.slice(offset, offset + perPage);
        const paginate = e => this.setState({ page: e.selected });
        return(
            <>
                {videoDetail && 
                    <Modal show={editVideoModal} onHide={this.handleCloseEditModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered backdrop="static">
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                Edit
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>    
                            <Form>
                                <Row>
                                    <Col sm={12} md={12}>
                                        <Form.Group>
                                            <Form.Label>Title</Form.Label>
                                            <Form.Control type="text" name="media" placeholder="Enter Title Ex. Love" defaultValue={videoDetail['media'] || ''} onChange={this.handleInput}/>
                                            <small style={{ color:'#db1313'}}> { errors['media'] || '' } </small>
                                        </Form.Group>
                                    </Col>                                 
                                    <Col sm={12} md={12}>
                                        <Form.Group>
                                            <Form.Label>Video Tags</Form.Label>
                                            {(videoDetail['videoTags'] && videoDetail['videoTags'].length > 0) &&
                                                <ul>
                                                    {videoDetail['videoTags'].map((t, i) => (
                                                        <OverlayTrigger
                                                            key={i} 
                                                            placement="top"
                                                            overlay={
                                                                <Tooltip id={`tooltip-project`}>
                                                                    Double click to remove
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <li onDoubleClick={() => this.handleRemoveTagOnDoubleClick(t)}>
                                                                {t}
                                                            </li>
                                                        </OverlayTrigger>
                                                    ))}
                                                </ul>
                                            }
                                            <Form.Control type="text" className="form-control mb-1" id="tag" name="tag" aria-describedby="tag" ref={r => this.inputTags = r} placeholder="Enter Tag"  onKeyUp={this.handleAddTag}/>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12} className="d-sm-flex align-items-center justify-content-end">
                                        <Button size="sm" variant="primary" className="text-capitalize mr-2" disabled={!isFormValid || updateLoader } onClick={(e) => this.handleVideoUpdate(e)}> {updateLoader ? 'wait...' : 'Submit'} </Button>
                                        <Button size="sm" variant="outline-secondary" className="text-capitalize" onClick={() => this.handleCloseEditModal()}> Cancel </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Modal.Body>
                    </Modal>
                }
            
                <div className="uploadForm">
                    <Container fluid>
                        <Row>
                            <Col sm={12}>                            
                                <div className="card mb-4">
                                    <div className="card-header px-3 d-lg-flex flex-row align-items-center justify-content-between">
                                        <h6 className="m-0 mb-3 mb-lg-0"><strong>Videos List</strong></h6>
                                        <div className="d-md-flex flex-row align-items-center justify-content-between">
                                            <div className="table-entries mx-lg-2 my-3 my-md-0 d-flex flex-row align-items-center justify-content-between">
                                                <Button size="sm"  className="addVideo" onClick={() => this.props.history.push(`/video-upload`)} > Add Video </Button>
                                            </div>
                                            <Form inline>
                                                <Form.Label className="mr-2" htmlFor="inlineFormCustomSelectPref">
                                                    Search
                                                </Form.Label>
                                                <Form.Control type="text" size="sm" placeholder="Search Here By Title..." onChange={this.handleSearchInput} value={searchInput} />
                                            </Form>  
                                            <div className="table-entries mx-lg-2 my-3 my-md-0 d-flex flex-row align-items-center justify-content-between">
                                                <span> Show </span>
                                                <Form.Control as= "select" className="mx-2" size ="sm" refs="perPpage" defaultValue={perPage} selected={perPage} onChange={(e) => this.onChangeEntries(e)} custom>
                                                    <option value="5"> 5 Entries </option>
                                                    <option value="10"> 10 Entries </option>
                                                    <option value="20"> 20 Entries</option>
                                                    <option value="30"> 30 Entries</option>
                                                    <option value="50"> 50 Entries</option>
                                                    <option value="100"> 100 Entries</option>
                                                </Form.Control>
                                            </div>
                                        </div>
                                    </div>
                                    {loader
                                    ?
                                    <div className="text-center mt-3 mb-3"><Spinner as="span" animation="border" size="sm" role="status" /></div>
                                    :
                                    (videos && videos.length > 0)
                                        ?
                                        <Table responsive>
                                            <thead>
                                                <tr>
                                                    <th>S.No.</th>
                                                    <th>Title</th>
                                                    <th>Uploaded By</th>
                                                    <th>Date of Uploading</th>
                                                    <th>Video Tags</th>
                                                    <th>Status</th>
                                                    <th style={{ textAlign: 'center'}}>Actions</th>
                                                    {/* <th></th> */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (currentResults.length ? currentResults : videos).map((video, idx) => {
                                                        return (
                                                            <tr key={idx}>
                                                                <th scope="row"> {perPage * (page) + idx + 1} </th>
                                                                <td> {video.media || 'N/A'} </td>
                                                                <td> {video.uploadedBy.fullname } </td>
                                                                <td> {video.createdAt ? (new Date(video.createdAt.split('T')[0])).toLocaleString('en', {weekday: 'short', year: 'numeric', month: 'short', day: 'numeric'}) : 'N/A'} </td>
                                                                <td> 
                                                                    { video.videoTags.length ? 
                                                                            video.videoTags.length > 5 
                                                                            ? video.videoTags.slice(0,4).join(',') +"..." 
                                                                            : video.videoTags.join(',')
                                                                       :
                                                                        'Not Available / Add Tags Manually'
                                                                    } 
                                                                </td>
                                                                <th><span style={{ textTransform:'capitalize' }}>{video.status}</span></th>
                                                                <td style={{ textAlign: 'center'}}>
                                                                <span className="mr-1">
                                                                    <OverlayTrigger
                                                                        placement="auto"
                                                                        overlay={
                                                                            <Tooltip id={`tooltip-script_view_${'1'}`}>
                                                                                Click here to view video
                                                                            </Tooltip>
                                                                        }
                                                                        >   
                                                                        
                                                                        <i className="fa fa-eye" style={{ cursor: 'pointer' }} onClick={() => this.handleViewOnScript(video)} />
                                                                    </OverlayTrigger>
                                                                </span>

                                                                <span className="mr-1">
                                                                    <OverlayTrigger

                                                                        placement="auto"
                                                                        overlay={
                                                                            <Tooltip id={`tooltip-script_del_${idx}`}>
                                                                                Delete Video
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <i className="fa fa-trash-o" style={{ cursor: 'pointer', backgroundColor:'red' }} onClick={() => this.handleDeleteVideo(video._id)} />
                                                                    </OverlayTrigger>
                                                                </span>
                                                                <span className="mr-1">
                                                                    <OverlayTrigger
                                                                        placement="auto"
                                                                        overlay={
                                                                            <Tooltip id={`tooltip-script_del_${idx}`}>
                                                                                View
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <i className="fa fa-pencil" style={{ cursor: 'pointer', backgroundColor:'green' }} onClick={() => this.handleEditVideo(video)} />
                                                                    </OverlayTrigger>
                                                                </span>
                                                                </td>

                                                                {/* <td style={{ display: 'flex', alignItems: 'center' }}>   
                                                                    
                                                                    <span className="mr-1">
                                                                        <OverlayTrigger

                                                                            placement="auto"
                                                                            overlay={
                                                                                <Tooltip id={`tooltip-script_del_${idx}`}>
                                                                                    Delete Video
                                                                                </Tooltip>
                                                                            }
                                                                        >
                                                                            <i className="fa fa-trash-o" style={{ cursor: 'pointer', backgroundColor:'red' }} onClick={() => this.handleDeleteVideo(video._id)} />
                                                                        </OverlayTrigger>
                                                                    </span>
                                                                </td> */}
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </Table>
                                        :
                                             <p className="mt-3 mb-3 text-center" style={{ textTransform: 'uppercase' }}>  Nothing to show </p>
                                        }

                                        {(videos.length > 0 && pageNumbers > 1) &&
                                            <section className="pagination my-3">
                                                <ReactPaginate
                                                    previousLabel={'<<'}
                                                    nextLabel={'>>'}
                                                    breakLabel={'...'}
                                                    breakClassName={'break-me'}
                                                    pageCount={pageNumbers}
                                                    marginPagesDisplayed={2}
                                                    pageRangeDisplayed={3}
                                                    onPageChange={paginate}
                                                    containerClassName={'pagination'}
                                                    subContainerClassName={'pages pagination'}
                                                    activeClassName={'active'}
                                                />
                                            </section>
                                        }
                                    {/* <Table responsive>
                                        <thead>
                                            <tr>
                                                <th>S.No.</th>
                                                <th>Title</th>
                                                <th>Length</th>
                                                <th>Tags</th>
                                                <th>Uploaded By</th>
                                                <th>Date of Uploading</th>
                                                <th style={{ textAlign: 'center'}}>Actions</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>Video Sample</td>
                                                <td>1 min</td>
                                                <td>Culture</td>
                                                <td>Anmol Sharma</td>
                                                <td>12-09-2021</td>
                                                <td style={{ textAlign: 'center'}}>
                                                    <OverlayTrigger
                                                        placement="auto"
                                                        overlay={
                                                            <Tooltip id={`tooltip-script_view_${'1'}`}>
                                                                Click here to view video
                                                            </Tooltip>
                                                        }
                                                        >   
                                                        <Button variant="outline-primary" onClick={() => this.handleViewOnScript()}>View</Button>
                                                    </OverlayTrigger>
                                                </td>
                                                <td style={{ textAlign: 'center'}}>
                                                    <OverlayTrigger
                                                        placement="auto"
                                                        overlay={
                                                            <Tooltip id={`tooltip-script_view_${'1'}`}>
                                                                Click here to Edit video
                                                            </Tooltip>
                                                        }
                                                        >   
                                                        <i class="fas fa-pen" onClick={() => this.handleEditOnScript()}></i>
                                                    </OverlayTrigger>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>Video Sample</td>
                                                <td>1 min</td>
                                                <td>Culture</td>
                                                <td>Anmol Sharma</td>
                                                <td>12-09-2021</td>
                                                <td style={{ textAlign: 'center'}}>
                                                    <OverlayTrigger
                                                        placement="auto"
                                                        overlay={
                                                            <Tooltip id={`tooltip-script_view_${'1'}`}>
                                                                Click here to view video
                                                            </Tooltip>
                                                        }
                                                        >   
                                                        <Button variant="outline-primary" onClick={() => this.handleViewOnScript()}>View</Button>
                                                    </OverlayTrigger>
                                                </td>
                                                <td style={{ textAlign: 'center'}}>
                                                    <OverlayTrigger
                                                        placement="auto"
                                                        overlay={
                                                            <Tooltip id={`tooltip-script_view_${'1'}`}>
                                                                Click here to Edit video
                                                            </Tooltip>
                                                        }
                                                        >   
                                                        <i class="fas fa-pen" onClick={() => this.handleViewOnScript()}></i>
                                                    </OverlayTrigger>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>                                  */}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </>
        )
    }
}
const mapStateToProps = state => {
    const { video } = state
    return {
        videos: video.getVideos,
        delVideo: video.delVideo,
        updVideo: video.updateVideo
    }
}

const dispatchActions = { getVideos, deleteVideo, updateVideo  }

export default connect(mapStateToProps, dispatchActions)(VideoListComponent);