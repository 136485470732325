import React, { Component } from 'react';

class Footer extends Component {
    render() {
        return (
            <footer className="sticky-footer bg-white">
                <div className="container my-auto">
                    <div className="copyright text-center my-auto">
                        <span>Copyright &copy; 2020 - {new Date().getFullYear()} CV Global. All Rights Reserved</span>
                    </div>
                </div>
            </footer>
        )
    }
}

export default Footer;