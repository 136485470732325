import { 
    USER_LOGIN, 
    USER_LOGOUT, 
    USER_FORGOT_PASSWORD, 
    LIST_USERS,
    DELETE_USER,
    USER_ROLES ,
    CHANGE_USER_ROLES,
    COUNT_USERS,
    UPDATE_USER,
    GET_USER, 
    UPDATE_PROFILE
} from "../actions/types";

const initialState = { user: '',  listUsers:'', deleteResponse:'', rolesResponse:'', updateRoleResponse:'', count:'', update:'', get:'', updateProfile:''}

export default ( state = initialState, action ) => {

    switch (action.type) {
        case USER_LOGIN:
            return {
                ...state,
                user: action.payload
            }
        case USER_LOGOUT:
            return {}
        case USER_FORGOT_PASSWORD:
            return {
                ...state,
                user: action.payload
            }
        case LIST_USERS:
            return {
                ...state,
                listUsers: action.payload
            } 
        case DELETE_USER:
            return {
                ...state,
                deleteResponse:action.payload
            } 
        case USER_ROLES:
            return {
                ...state,
                rolesResponse: action.payload
            }
        case CHANGE_USER_ROLES:
            return {
                ...state,
                updateRoleResponse: action.payload
            }
        case COUNT_USERS:
            return {
                ...state,
                count: action.payload
            }
        case UPDATE_USER:
            return {
                ...state,
                update: action.payload
            }
        case GET_USER:
            return {
                ...state,
                get: action.payload
            }
        case UPDATE_PROFILE:
            return {
                ...state,
                updateProfile: action.payload
            }
        default: return state;
    }

}
