import React, { Component } from 'react';
import { getScripts, deleteScript, updateScript, addScript } from '../../actions/scriptActions';
import { getThemes } from '../../actions/themeActions';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table, Spinner, Modal, Form, Button, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import * as check from '../../helpers/validation';
import { jsPDF } from "jspdf";
import swal from 'sweetalert';
import JSZip from 'jszip';
import { saveAs } from "file-saver";
import {pdf} from '@react-pdf/renderer';
import MyDocument from "./PdfFileFormeter";
import * as SwalReact from "@sweetalert/with-react";
import { PDFDownloadLink } from '@react-pdf/renderer';
import { toast } from 'react-toastify';
import { ENV } from '../../env';
toast.configure()


class ListScript extends Component {
    constructor(props) {
        super(props)

        this.state = {
            scriptDbDowload:[],
            scripts: [],
            scriptDetail: {},
            errors: {},
            backupScripts: [],
            loader: false,
            updateLoader: false,
            page: 0,
            perPage: 10,
            searchInput: '',

            editScriptModal: false,
            isFormValid: false,
            submitLoader: false,

            refreshLoading: false,

            showUserModal: false,
            uploadedUser: {},

            showAddScriptModal: false,
            addScriptLoader: false,
            addScriptData: {
                tags: [],
                script: '',
                language: '',
                title: '',
                themeId: '',
                subThemeId: '',
                feltNeed: ''
                // length:''
            },
            isAddScriptFormValid: false,
            themes: [],
            subThemes: [],
            isCheckAll:false,
        }
    }

    componentDidMount = async () => {
        this.handleLoader('loader', true)
        await this.handleFetchScripts();
        this.fetchThemes();
    }

    handleLoader = (name, value) => { this.setState({ [name]: value }) }


    handleFetchScripts = async () => {
        await this.props.getScripts();

        if (this.props.scripts.success) {
            this.setState({
                scripts: this.props.scripts.data,
                backupScripts: this.props.scripts.data
            })
        } else {
            this.handleLoader('loader', false)
            toast.dismiss()
            toast.error(this.props.scripts.message, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true })
        }
    }

    fetchThemes = async () => {
        await this.props.getThemes();
        if (this.props.themes.response) {
            this.handleLoader('loader', false)

            this.setState({
                themes: this.props.themes.themes
            })

        } else {
            this.setState({ loader: false })
            toast.dismiss()
            toast.error(this.props.themes.message, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true })
        }

    }

    fetchSubThemes = async (themeId) => {

        var subThemes = this.state.themes.filter(t => t._id.toString() === themeId.toString())

        this.state.scriptDetail["subThemeId"] = ""
        this.setState({
            scriptDetail: this.state.scriptDetail
        }, () => {
            this.setState({
                subThemes: subThemes[0].subthemes
            })
        })
    }

    refreshData = async () => {
        this.setState({ refreshLoading: true })
        await this.handleFetchScripts()
        this.setState({ refreshLoading: false })
    }


    handleSearchInput = (e) => {
        const { value } = e.target

        let scripts = this.state.backupScripts;
        if (value.length > 0) {
            scripts = scripts.filter(script => {
                if (script['title'] !== undefined && script['themeId'] !== undefined && script['tags'] !== undefined && script['language'] !== undefined)
                    return script['title'].toLowerCase().match(value.toLowerCase()) || script['themeId'].name.toLowerCase().match(value.toLowerCase()) || script['tags'].find(e => e.toLowerCase() === value.toLowerCase()) || script['language'].toLowerCase().match(value.toLowerCase())
            })
            this.setState({ scripts })
        } else {
            this.setState({ scripts })
        }
        this.setState({ searchInput: value })
    }

    handleEditScript = async (script) => {
        if (script.themeId) {

            var subThemes = this.state.themes.filter(t => t._id == script.themeId._id)

            script.themeId = script.themeId._id;

            if (script.subThemeId == null) {
                script.subThemeId = "";
            } else if (!subThemes[0].subthemes.some(obj => obj._id === script.subThemeId._id)) {
                script.subThemeId = "";
            } else {
                script.subThemeId = script.subThemeId._id;
            }

            this.setState({
                scriptDetail: script,
                subThemes: subThemes[0].subthemes,
                editScriptModal: true
            })
        } else {
            script.subThemeId = "";
            script.themeId = "";
            this.setState({
                scriptDetail: script,
                subThemes: [],
                editScriptModal: true
            })

        }
    }

    handleDeleteScript = async (id) => {

        swal({
            title: "Are you sure?",
            text: "Once deleted, it won't be revert",
            icon: "warning",
            buttons: ['Cancel', 'Yes'],
            dangerMode: true,
        })
            .then(async (willDelete) => {
                if (willDelete) {
                    await this.props.deleteScript(id)
                    if (this.props.delScript.success) {
                        this.handleFetchScripts();
                        swal(this.props.delScript.message, { icon: "success" });
                    } else {
                        swal(this.props.delScript.message, { icon: "error" });
                    }
                }
            });
    }

    handleChangeDownloadmultiScript=(e,script)=>{
        const { name, checked} = e.target
       if(name === "selectAll"){
        let tt = (this.state.scripts).map((script, idx) => {
            return ({...script, ischecked:checked} )
        })
       }
        let scriptDbDowload = this.state.scriptDbDowload
        if(e.target.checked){
            scriptDbDowload.push(script)
        }
        else{
            if(scriptDbDowload.indexOf(script !== -1)){
                scriptDbDowload = scriptDbDowload.filter((itm)=> itm !== script)
            }
        }
        this.setState({scriptDbDowload});
    }



    handleViewOnScript = (script) => {
        SwalReact({
            buttons: {
                cancel: "Close"
            },
            content: (
                <div style={{ padding: '0.25rem' }}>
                    <h3 className="text-left"> {script.title || 'N/A'} </h3>
                    <h5 className="text-muted text-left"> {(script.themeId && script.themeId.name) ? script.themeId.name : 'N/A'} </h5>
                    <p className="mt-3 mb-0" style={{ textAlign: 'justify', whiteSpace: 'pre-line' }}> {script.script || 'N/A'} </p>
                    <h5 className="text-left mt-3 text-muted"> Keywords: <small>{(script.tags && script.tags.length) ? script.tags.join(', ') : 'N/A'}</small></h5>
                    {/* <p className="text-muted d-inline text_key  mt-1"> </p> */}
                    <hr />
                    <h5>Download Script </h5>
                    <Row className="text-center">
                    <PDFDownloadLink document={<MyDocument x={script}/>} fileName={`${script.title}_${Date.now()}.pdf`}>
                        <span 
                        // onClick={() => this.downloadAsPDF(script)}
                        >
                            <svg viewBox="0 0 512 512" >
                                <path d="M444.875,109.792L338.208,3.125c-2-2-4.708-3.125-7.542-3.125h-224C83.135,0,64,19.135,64,42.667v426.667     C64,492.865,83.135,512,106.667,512h298.667C428.865,512,448,492.865,448,469.333v-352     C448,114.5,446.875,111.792,444.875,109.792z M341.333,36.417l70.25,70.25h-48.917c-11.76,0-21.333-9.573-21.333-21.333V36.417z      M426.667,469.333c0,11.76-9.573,21.333-21.333,21.333H106.667c-11.76,0-21.333-9.573-21.333-21.333V42.667     c0-11.76,9.573-21.333,21.333-21.333H320v64C320,108.865,339.135,128,362.667,128h64V469.333z" />
                                <path d="M310.385,313.135c-9.875-7.771-19.26-15.76-25.51-22.01c-8.125-8.125-15.365-16-21.656-23.5     c9.813-30.323,14.115-45.958,14.115-54.292c0-35.406-12.792-42.667-32-42.667c-14.594,0-32,7.583-32,43.688     c0,15.917,8.719,35.24,26,57.698c-4.229,12.906-9.198,27.792-14.781,44.573c-2.688,8.052-5.604,15.51-8.688,22.406     c-2.51,1.115-4.948,2.25-7.302,3.427c-8.479,4.24-16.531,8.052-24,11.594C150.5,370.177,128,380.844,128,401.906     c0,15.292,16.615,24.76,32,24.76c19.833,0,49.781-26.49,71.656-71.115c22.708-8.958,50.938-15.594,73.219-19.75     c17.854,13.729,37.573,26.865,47.125,26.865c26.448,0,32-15.292,32-28.115c0-25.219-28.813-25.219-42.667-25.219     C337.031,309.333,325.49,310.604,310.385,313.135z M160,405.333c-6.094,0-10.219-2.875-10.667-3.427     c0-7.563,22.552-18.25,44.365-28.583c1.385-0.656,2.792-1.313,4.219-1.99C181.896,394.563,166.052,405.333,160,405.333z      M234.667,214.354c0-22.354,6.938-22.354,10.667-22.354c7.542,0,10.667,0,10.667,21.333c0,4.5-3,15.75-8.49,33.313     C239.135,233.75,234.667,222.698,234.667,214.354z M242.844,329c0.667-1.854,1.313-3.729,1.938-5.625     c3.958-11.875,7.521-22.542,10.698-32.146c4.427,4.875,9.198,9.865,14.313,14.979c2,2,6.958,6.5,13.563,12.135     C270.208,321.208,256.219,324.76,242.844,329z M362.667,334.552c0,4.792,0,6.781-9.896,6.844     c-2.906-0.625-9.625-4.583-17.917-10.229c3.01-0.333,5.229-0.5,6.479-0.5C357.094,330.667,361.563,332.208,362.667,334.552z" />
                            </svg>
                        </span>
                        </PDFDownloadLink>
                        <span onClick={() => this.downloadDocFile(script)}>
                            <svg viewBox="0 0 512 512">
                                <path d="M282.208,19.67c-3.648-3.008-8.48-4.256-13.152-3.392l-256,48C5.472,65.686,0,72.278,0,79.99v352    c0,7.68,5.472,14.304,13.056,15.712l256,48c0.96,0.192,1.984,0.288,2.944,0.288c3.68,0,7.328-1.28,10.208-3.68    c3.68-3.04,5.792-7.584,5.792-12.32v-448C288,27.222,285.888,22.71,282.208,19.67z M256,460.694L32,418.71V93.27l224-41.984    V460.694z" />
                                <path d="M496,79.99H272c-8.832,0-16,7.168-16,16c0,8.832,7.168,16,16,16h208v288H272c-8.832,0-16,7.168-16,16    c0,8.832,7.168,16,16,16h224c8.832,0,16-7.168,16-16v-320C512,87.158,504.832,79.99,496,79.99z" />
                                <path d="M432,143.99H272c-8.832,0-16,7.168-16,16c0,8.832,7.168,16,16,16h160c8.832,0,16-7.168,16-16    C448,151.158,440.832,143.99,432,143.99z" />
                                <path d="M432,207.99H272c-8.832,0-16,7.168-16,16c0,8.832,7.168,16,16,16h160c8.832,0,16-7.168,16-16    C448,215.158,440.832,207.99,432,207.99z" />
                                <path d="M432,271.99H272c-8.832,0-16,7.168-16,16c0,8.832,7.168,16,16,16h160c8.832,0,16-7.168,16-16    C448,279.158,440.832,271.99,432,271.99z" />
                                <path d="M432,335.99H272c-8.832,0-16,7.168-16,16c0,8.832,7.168,16,16,16h160c8.832,0,16-7.168,16-16    C448,343.158,440.832,335.99,432,335.99z" />
                                <path d="M209.76,176.086c-8.48-0.864-16.704,5.344-17.664,14.112l-8.608,77.504l-24.512-65.344    c-4.704-12.48-25.312-12.48-29.984,0l-26.016,69.408l-7.136-50.048c-1.248-8.768-9.44-14.976-18.112-13.568    c-8.736,1.248-14.816,9.344-13.568,18.08l16,112c1.024,7.264,6.848,12.896,14.112,13.664c7.424,0.736,14.144-3.424,16.704-10.272    L144,253.558l33.024,88.064c2.368,6.272,8.384,10.368,14.976,10.368c0.672,0,1.312-0.032,1.984-0.16    c7.328-0.896,13.088-6.752,13.92-14.08l16-144C224.864,184.982,218.56,177.078,209.76,176.086z" />
                            </svg>
                        </span>
                        <span onClick={() => this.downloadTxtFile(script)}>
                            <svg viewBox="0 0 24 24">
                                <g><path d="m12.25 12c-.414 0-.75-.336-.75-.75v-.75h-7v.75c0 .414-.336.75-.75.75s-.75-.336-.75-.75v-1.5c0-.414.336-.75.75-.75h8.5c.414 0 .75.336.75.75v1.5c0 .414-.336.75-.75.75z" /></g>
                                <g><path d="m8 18.75c-.414 0-.75-.336-.75-.75v-8c0-.414.336-.75.75-.75s.75.336.75.75v8c0 .414-.336.75-.75.75z" /></g>
                                <g><path d="m9.25 19h-2.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h2.5c.414 0 .75.336.75.75s-.336.75-.75.75z" /></g>
                                <g><path d="m20.25 10.5h-4.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h4.5c.414 0 .75.336.75.75s-.336.75-.75.75z" /></g>
                                <g><path d="m20.25 14.5h-4.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h4.5c.414 0 .75.336.75.75s-.336.75-.75.75z" /></g>
                                <g><path d="m20.25 18.5h-4.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h4.5c.414 0 .75.336.75.75s-.336.75-.75.75z" /></g>
                                <g><path d="m21.25 23h-18.5c-1.517 0-2.75-1.233-2.75-2.75v-16.5c0-1.517 1.233-2.75 2.75-2.75h18.5c1.517 0 2.75 1.233 2.75 2.75v16.5c0 1.517-1.233 2.75-2.75 2.75zm-18.5-20.5c-.689 0-1.25.561-1.25 1.25v16.5c0 .689.561 1.25 1.25 1.25h18.5c.689 0 1.25-.561 1.25-1.25v-16.5c0-.689-.561-1.25-1.25-1.25z" /></g>
                                <g><path d="m23.25 6h-22.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h22.5c.414 0 .75.336.75.75s-.336.75-.75.75z" /></g>
                            </svg>
                        </span>
                        {/* <span onClick={() => this.downloadAsSheet(script)}>
                            <i class="fa fa-file-excel-o" aria-hidden="true"></i>
                        </span> */}
                    </Row>
                </div>
            )
        })
    }

    fieldValidation = (name) => {
        const { scriptDetail } = this.state
        let errors = this.state.errors
        let isValid = true

        if (name === 'title') {
            if (!scriptDetail['title']) {
                isValid = false
                errors['title'] = "Can't be blank"
            }

            if (scriptDetail['title'] !== undefined) {

                if (scriptDetail['title'].trim().length < 1) {
                    isValid = false
                    errors['title'] = "Can't be blank"
                }
                if (scriptDetail['title'].length < 3) {
                    isValid = false
                    errors['title'] = "Minimum length must be 3"
                }
                if (!check.AphabeticalsValidation(scriptDetail['title'])) {
                    isValid = false
                    errors['title'] = "Enter only letters"
                }
            }
        }

        if (name === 'themeId') {
            if (!scriptDetail['themeId']) {
                isValid = false
                errors['themeId'] = "Please Select Theme"
            }
        }

        if (name === 'subThemeId') {
            if (!scriptDetail['subThemeId']) {
                isValid = false
                errors['subThemeId'] = "Please Select Sub Theme"
            }
        }

        if (name === 'feltNeed') {
            if (!scriptDetail['feltNeed']) {
                isValid = false
                errors['feltNeed'] = "Please Select Felt Need"
            }
        }

        // if(name === 'length'){
        //     if(!scriptDetail['length']){
        //         isValid = false
        //         errors['length'] = "Can't be blank"
        //     }

        //     if(isNaN(scriptDetail['length'])){
        //         isValid = false
        //         errors['length'] = "Enter length in number"
        //     }
        //     if(!isNaN(scriptDetail['length'])){
        //         if(scriptDetail['length'] < 30 ){
        //             isValid = false
        //             errors['length'] = 'Minimum length must be 30 Seconds'
        //         }
        //     }
        // }

        if (name === 'language') {
            if (!scriptDetail['language']) {
                isValid = false
                errors['language'] = "Can't be blank"
            }

            if (scriptDetail['language'] !== undefined) {
                if (scriptDetail['language'].trim().length < 1) {
                    isValid = false
                    errors['language'] = "Can't be blank"
                }
                if (scriptDetail['language'].length < 3) {
                    isValid = false
                    errors['language'] = "Minimum length must be 3"
                }
                if (!check.AphabeticalsValidation(scriptDetail['language'])) {
                    isValid = false
                    errors['language'] = "Enter only letters"
                }
            }
        }

        if (name === 'script') {
            if (!scriptDetail['script']) {
                isValid = false
                errors['script'] = "Can't be blank";
            }

            if (scriptDetail['script'] !== undefined) {
                if (scriptDetail['script'].trim().length < 1) {
                    isValid = false
                    errors['script'] = "Can't be blank"
                }
                if (scriptDetail['script'].length < 30) {
                    isValid = false
                    errors['script'] = "Minimum length must be 30"
                }
            }
        }

        this.setState({ errors })
        return isValid
    }

    isScriptFormValid = () => {
        const { scriptDetail } = this.state
        let isValid = true

        if (!scriptDetail['title']) {
            isValid = false
        }

        if (!check.AphabeticalsValidation(scriptDetail['title'])) {
            isValid = false
        }

        if (scriptDetail['title'] !== undefined) {
            if (scriptDetail['title'].trim().length < 1) {
                isValid = false
            }
            if (scriptDetail['title'].length < 3) {
                isValid = false
            }
        }

        if (!scriptDetail['themeId']) {
            isValid = false
        }

        if (!scriptDetail['subThemeId']) {
            isValid = false
        }

        if (!scriptDetail['feltNeed']) {
            isValid = false
        }



        // if(!scriptDetail['length']){
        //     isValid = false
        // }

        // if(isNaN(scriptDetail['length'])){
        //     isValid = false
        // }

        // if(!isNaN(scriptDetail['length'])){
        //     if(scriptDetail['length'] < 30 ){
        //         isValid = false
        //     }
        // }

        if (!scriptDetail['language']) {
            isValid = false

        }

        if (!check.AphabeticalsValidation(scriptDetail['language'])) {
            isValid = false

        }

        if (scriptDetail['language'] !== undefined) {
            if (scriptDetail['language'].trim().length < 1) {
                isValid = false

            }
            if (scriptDetail['language'].length < 3) {
                isValid = false

            }
        }

        if (scriptDetail['tags'] !== undefined && scriptDetail['tags'].length < 1) {
            isValid = false
        }

        if (scriptDetail['script'] !== undefined && scriptDetail['script'].length < 30) {
            isValid = false
        }

        return isValid
    }

    handleInput = (e) => {
        const { value, name } = e.target
        let scriptDetail = this.state['scriptDetail']
        let errors = this.state['errors']

        if (name === 'themeId' && name === 'subThemeId') {
            scriptDetail[name] = (value)
        } else {
            scriptDetail[name] = check.capitalizeFirstLetter(value)
        }

        if (name == "themeId") {
            this.fetchSubThemes(value)
        }

        let isFieldValid = this.fieldValidation(name);
        let isFormValid = this.isScriptFormValid();

        if (isFieldValid) {
            errors[name] = ""
        }


        this.setState({ scriptDetail, isFormValid, errors })
    }

    handleAddTag = (e) => {
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
            let errors = this.state.errors
            errors['tag'] = ''
            this.setState({ errors })
            if (this.inputTags.value.length < 1) return;

            if (this.inputTags.value.trim().length < 1) return;

            let scriptDetail = this.state.scriptDetail

            scriptDetail['tags'] = scriptDetail['tags'].slice()
            let tagFound = scriptDetail['tags'].find(t => t.toLowerCase() === this.inputTags.value.toLowerCase())
            if (tagFound) {
                errors['tag'] = `Can't add duplicate tag`
                this.setState({ errors })
                return;
            }

            scriptDetail['tags'].push(check.capitalizeFirstLetter(this.inputTags.value));
            errors['tag'] = ''

            // ----- Used till approve buttons are hidden ------ //
            if (scriptDetail['tags'].length > 0) {
                this.setState({ isFormValid: true })
            }
            // ----- Used till approve buttons are hidden ------ //

            this.setState({ scriptDetail, errors });
            this.inputTags.value = '';
        }, 750)
    }

    handleRemoveTagOnDoubleClick = (tag) => {
        let { scriptDetail } = this.state
        scriptDetail['tags'] = scriptDetail['tags'].filter(t => t !== tag)
        if (scriptDetail['tags'].length < 1) {

            this.setState({ isFormValid: false })
        }
        this.setState({ scriptDetail })

    }

    handleScriptUpdate = async (e) => {
        e.preventDefault()
        this.handleLoader('updateLoader', true)
        await this.props.updateScript(this.state['scriptDetail']._id, this.state.scriptDetail)
        if (this.props.updScript.success) {
            this.handleLoader('updateLoader', false)
            toast.dismiss()
            toast.success(this.props.updScript.message, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true })
            setTimeout(() => {
                this.handleCloseEditModal()
            }, 1200)
        } else {
            this.handleLoader('updateLoader', false)
            toast.dismiss()
            toast.error(this.props.updScript.message, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true })
        }
    }

    handleCloseEditModal = async () => {
        this.setState({ editScriptModal: false, scriptDetail: {} })
        await this.handleFetchScripts()
    }

    onChangeEntries = (e) => {
        const { value } = e.target
        let scripts = this.state.backupScripts
        this.setState({ perPage: Number(value), page: 0, searchInput: '', scripts })
    }


    downloadTxtFile = (selectedScript) => {
        try {
            const element = document.createElement("a");
            // let content = `${selectedScript.theme }\n\n\n`
            let content = `${selectedScript.title}\n\n\n`
            content += `${(selectedScript.themeId && selectedScript.themeId.name) ? selectedScript.themeId.name : " "}\n`;
            content += `\n`;
            content += `${selectedScript.script}\n`;
            content += `\n`;
            content += `Keywords: ${(selectedScript.tags && selectedScript.tags.length) ? selectedScript.tags.join(', ') : " "}\n`;
            const file = new Blob([content], { type: 'text/plain;charset=utf-8' });
            element.href = URL.createObjectURL(file);
            element.download = Date.now() + ".txt";
            document.body.appendChild(element); // Required for this to work in FireFox
            element.click();

        } catch (e) {
            toast.dismiss()
            toast.error("Something went wrong, Please try again", { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        }
    }

    downloadDocFile = (selectedScript) => {
        try {
            const element = document.createElement("a");
            // let content = `${selectedScript.theme }\n\n\n`
            let content = `${selectedScript.title}\n\n\n`
            content += `${(selectedScript.themeId && selectedScript.themeId.name) ? selectedScript.themeId.name : " "}\n`;
            content += `\n`;
            content += `${selectedScript.script}\n`;
            content += `\n`;
            content += `Keywords: ${(selectedScript.tags && selectedScript.tags.length) ? selectedScript.tags.join(', ') : " "}\n`;
            const file = new Blob([content],
                { type: 'application/msword;charset=utf-8' });
            element.href = URL.createObjectURL(file);
            element.download = Date.now() + ".docx";
            document.body.appendChild(element); // Required for this to work in FireFox
            element.click();

        } catch (e) {
            toast.dismiss()
            toast.error("Something went wrong, Please try again", { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        }
    }

    downloadAsPDF = (selectedScript) => {
        try {
            const doc = new jsPDF();
            doc.setFontSize(18);
            // doc.text(selectedScript.theme, 20, 20); // title
            let content_titile = doc.splitTextToSize(selectedScript.title, 200)
            // doc.text(selectedScript.title, 20, 20); // title
            doc.setFontSize(14);
            let content_theme = doc.splitTextToSize( (selectedScript.themeId && selectedScript.themeId.name) ? selectedScript.themeId.name :  '', 185)
            let content_keyword = doc.splitTextToSize( `Keywords: ${(selectedScript.tags && selectedScript.tags.length) ? selectedScript.tags.join(', ') : ''}`, 185)
            let content = doc.splitTextToSize(selectedScript.script, 185)
            doc.text(content_titile, 20, 30);
            doc.text(content_theme, 20, 45);
            doc.text(content, 20, 55);
            doc.setFontSize(12);
            doc.text(content_keyword, 20, 160);
            doc.save(Date.now() + ".pdf")

        } catch (e) {
            toast.dismiss()
            toast.error("Something went wrong, Please try again", { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        }
    }

    downloadAsPDFZip = async (selectedScript) => {
        try {
            const zip = new JSZip();
            selectedScript.forEach(x => {
                zip.file(`${x.title}_${Date.now()}.pdf`, pdf(<MyDocument x={x}/>).toBlob());
            })
            zip.generateAsync({type: "blob"}).then(blob => {saveAs(blob, "Script.zip")});          
          } catch (e) {
            toast.dismiss()
            toast.error("Something went wrong, Please try again", { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
          }
    }

    downloadDocFileZip = async (selectedScript)=>{
        try {
            const zip = new JSZip();
            selectedScript.forEach(x => {
                // zip.file(`${x._id}.txt`, `${x.title}`);
                // zip.file(`${moment(new Date(+(new Date()) - Math.floor(Math.random()*10000000000)))
                //     .format('MM/DD/YYYY')}.docx`, `${x.script}`);
                zip.file(`${x.title}_${Date.now()}.docx`, pdf(<MyDocument x={x}/>).toBlob());
            })
            zip.generateAsync({type: "blob"}).then(blob => {saveAs(blob, "Script.zip")});          
          } catch (e) {
            toast.dismiss()
            toast.error("Something went wrong, Please try again", { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
            // toaster.notify("Cannot Download.Please try again");
          }
    }

    downloadTxtFileZip = async (selectedScript) => {
        try {
            const zip = new JSZip();
            selectedScript.forEach(x => {
                zip.file(`${x._id}.txt`, `${x.title}`);
                zip.file(`${x._id}.txt`, `${x.script}`);
            })
            zip.generateAsync({type: "blob"}).then(blob => {saveAs(blob, "Script.zip")});          
          } catch (e) {
            toast.dismiss()
            toast.error("Something went wrong, Please try again", { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
            // toaster.notify("Cannot Download.Please try again");
          }
    }

    downloadAsSheet = (selectedScript) => {
        const element = document.createElement("a");
        let content = `${selectedScript.theme}\n\n\n`
        content += `${selectedScript.script}\n`;
        const file = new Blob([content], { type: 'application/vnd.ms-excel;charset=utf-8' });
        element.href = URL.createObjectURL(file);
        element.download = Date.now() + ".docx";
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    }

    // ------------------------- Add Script Functions ------------------------------- //

    addScript_fieldValidation = (name) => {
        const { addScriptData } = this.state
        let errors = this.state.errors
        let isValid = true

        if (name === 'title') {
            if (!addScriptData['title']) {
                isValid = false
                errors['title'] = "Can't be blank"
            }

            if (addScriptData['title'] !== undefined) {

                if (addScriptData['title'].trim().length < 1) {
                    isValid = false
                    errors['title'] = "Can't be blank"
                }
                if (addScriptData['title'].length < 3) {
                    isValid = false
                    errors['title'] = "Minimum length must be 3"
                }
                if (!check.AphabeticalsValidation(addScriptData['title'])) {
                    isValid = false
                    errors['title'] = "Enter only letters"
                }
            }
        }

        if (name === 'themeId') {
            if (!addScriptData['themeId']) {
                isValid = false
                errors['themeId'] = "Please Select Theme"
            }
        }

        if (name === 'subThemeId') {
            if (!addScriptData['subThemeId']) {
                isValid = false
                errors['subThemeId'] = "Please Select Sub Theme"
            }
        }

        if (name === 'feltNeed') {
            if (!addScriptData['feltNeed']) {
                isValid = false
                errors['feltNeed'] = "Please Select Felt Need"
            }
        }

        // if(name === 'length'){
        //     if(!addScriptData['length']){
        //         isValid = false
        //         errors['length'] = "Can't be blank"
        //     }

        //     if(isNaN(addScriptData['length'])){
        //         isValid = false
        //         errors['length'] = "Enter length in number"
        //     }
        //     if(!isNaN(addScriptData['length'])){
        //         if(addScriptData['length'] < 30 ){
        //             isValid = false
        //             errors['length'] = 'Minimum length must be 30 Seconds'
        //         }
        //     }
        // }

        if (name === 'language') {
            if (!addScriptData['language']) {
                isValid = false
                errors['language'] = "Can't be blank"
            }

            if (addScriptData['language'] !== undefined) {
                if (addScriptData['language'].trim().length < 1) {
                    isValid = false
                    errors['language'] = "Can't be blank"
                }
                if (addScriptData['language'].length < 3) {
                    isValid = false
                    errors['language'] = "Minimum length must be 3"
                }
                if (!check.AphabeticalsValidation(addScriptData['language'])) {
                    isValid = false
                    errors['language'] = "Enter only letters"
                }
            }
        }

        if (name === 'script') {
            if (!addScriptData['script']) {
                isValid = false
                errors['script'] = "Can't be blank";
            }

            if (addScriptData['script'] !== undefined) {
                if (addScriptData['script'].trim().length < 1) {
                    isValid = false
                    errors['script'] = "Can't be blank"
                }
                if (addScriptData['script'].length < 30) {
                    isValid = false
                    errors['script'] = "Minimum length must be 30"
                }
            }
        }

        this.setState({ errors })
        return isValid
    }

    addScript_isScriptFormValid = () => {
        const { addScriptData } = this.state
        let isValid = true

        if (!addScriptData['title']) {
            isValid = false
        }

        if (!check.AphabeticalsValidation(addScriptData['title'])) {
            isValid = false
        }

        if (addScriptData['title'] !== undefined) {
            if (addScriptData['title'].trim().length < 1) {
                isValid = false
            }
            if (addScriptData['title'].length < 3) {
                isValid = false
            }
        }

        if (!addScriptData['themeId']) {
            isValid = false
        }

        if (!addScriptData['subThemeId']) {
            isValid = false
        }

        // if(!addScriptData['length']){
        //     isValid = false
        // }

        // if(isNaN(addScriptData['length'])){
        //     isValid = false
        // }

        // if(!isNaN(addScriptData['length'])){
        //     if(addScriptData['length'] < 30 ){
        //         isValid = false
        //     }
        // }

        if (!addScriptData['language']) {
            isValid = false

        }

        if (!check.AphabeticalsValidation(addScriptData['language'])) {
            isValid = false
        }

        if (addScriptData['language'] !== undefined) {
            if (addScriptData['language'].trim().length < 1) {
                isValid = false

            }
            if (addScriptData['language'].length < 3) {
                isValid = false

            }
        }

        if (addScriptData['tags'].length < 1) {
            isValid = false
        }

        if (addScriptData['script'].length < 30) {
            isValid = false
        }

        // if(!addScriptData['tags']){
        //     isValid = false
        // }

        // if(addScriptData['tags'] && addScriptData['tags'].length < 1){
        //     isValid = false
        // }

        return isValid
    }

    handleInput_addScript = (e) => {
        const { value, name } = e.target
        let addScriptData = this.state['addScriptData']
        let errors = this.state['errors']
        // if(name === 'length'){
        //     addScriptData[name] = Number(value)
        // }else{
        //     addScriptData[name] = check.capitalizeFirstLetter(value)
        // }

        if (name === 'themeId' && name === 'subThemeId') {
            addScriptData[name] = (value)
        } else {
            addScriptData[name] = check.capitalizeFirstLetter(value)
        }

        let isFieldValid = this.addScript_fieldValidation(name);
        let isAddScriptFormValid = this.addScript_isScriptFormValid();

        if (isFieldValid) {
            errors[name] = ""
        }

        if (name == "themeId") {
            this.fetchSubThemes(value)
        }

        this.setState({ addScriptData, isAddScriptFormValid, errors })
    }

    addScript_handleAddTag = (e) => {
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
            let errors = this.state.errors
            errors['tag'] = ''
            this.setState({ errors })
            if (this.addScriptInputTags.value.length < 1) return;

            if (this.addScriptInputTags.value.trim().length < 1) return;

            let addScriptData = this.state.addScriptData

            addScriptData['tags'] = addScriptData['tags'] !== undefined ? addScriptData['tags'].slice() : []
            let tagFound = addScriptData['tags'].find(t => t.toLowerCase() === this.addScriptInputTags.value.toLowerCase())
            if (tagFound) {
                errors['tag'] = `Can't add duplicate tag`
                this.setState({ errors })
                return;
            }

            addScriptData['tags'].push(check.capitalizeFirstLetter(this.addScriptInputTags.value));
            errors['tag'] = ''

            // ----- Used till approve buttons are hidden ------ //
            if (addScriptData['tags'].length > 0) {
                let isAddScriptFormValid = this.addScript_isScriptFormValid()
                if (!isAddScriptFormValid) {
                    this.setState({ isAddScriptFormValid })
                } else {
                    this.setState({ isAddScriptFormValid: true })
                }
            }
            // let isAddScriptFormValid =  this.addScript_isScriptFormValid()
            // ----- Used till approve buttons are hidden ------ //

            this.setState({ addScriptData, errors });
            this.addScriptInputTags.value = '';
        }, 750)
    }

    addScript_handleRemoveTagOnDoubleClick = (tag) => {
        let { addScriptData } = this.state
        addScriptData['tags'] = addScriptData['tags'].filter(t => t !== tag)
        if (addScriptData['tags'].length < 1) {

            this.setState({ isAddScriptFormValid: false })
        }


        this.setState({ addScriptData })
    }

    handleAddNewScript = async (e) => {
        e.preventDefault();
        this.handleLoader('addScriptLoader', true)
        await this.props.addScript(this.state.addScriptData)
        if (this.props.addedScript.success) {
            this.handleLoader('addScriptLoader', false)
            toast.dismiss()
            toast.success(this.props.addedScript.message, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true })
            setTimeout(() => {
                this.handleResetAddScriptForm()
                this.handleFetchScripts()
            }, 1200)
        } else {
            this.handleLoader('addScriptLoader', false)
            toast.dismiss()
            toast.error(this.props.addedScript.message, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true })
        }

    }

    handleResetAddScriptForm = () => {
        let showAddScriptModal = false
        let addScriptLoader = false
        let addScriptData = {
            tags: [],
            script: '',
            language: '',
            title: '',
            themeId: '',
            subThemeId: '',
            feltNeed: ''
            // length:''
        }
        let isAddScriptFormValid = false
        this.setState({
            showAddScriptModal,
            addScriptLoader,
            addScriptData,
            isAddScriptFormValid,
        })

    }

    // ------------------------- Add Script Functions ------------------------------- //


    render() {
        const { scripts, loader, page, perPage, searchInput, editScriptModal, scriptDetail, errors, updateLoader, refreshLoading, uploadedUser, showUserModal, showAddScriptModal, addScriptLoader, addScriptData, isAddScriptFormValid, themes, subThemes } = this.state

        const pageNumbers = Math.ceil(scripts.length / perPage);

        const offset = page * perPage;
        const currentResults = scripts.slice(offset, offset + perPage);
        const paginate = e => this.setState({ page: e.selected });
        let isFormValid = this.isScriptFormValid()
        var btoa = require('btoa');

        var token = btoa((localStorage.getItem('scriptDBToken_admin')));
        return (
            <>
                {scriptDetail &&
                    <Modal show={editScriptModal} onHide={this.handleCloseEditModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered backdrop="static">
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                Edit
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Row>
                                    <Col sm={12} md={12}>
                                        <Form.Group>
                                            <Form.Label>Title</Form.Label>
                                            <Form.Control type="text" name="title" placeholder="Enter Title Ex. Love" defaultValue={scriptDetail['title'] || ''} onChange={this.handleInput} />
                                            <small style={{ color: '#db1313' }}> {errors['title'] || ''} </small>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12} md={12}>
                                        <Form.Group>
                                            <Form.Label>Theme</Form.Label>
                                            <Form.Control as="select" name="themeId" custom defaultValue={scriptDetail['themeId'] !== undefined ? scriptDetail['themeId'] : ''} onChange={this.handleInput}>
                                                <option value="" disabled>Select Theme</option>
                                                {
                                                    (themes.length > 0 && themes.map((theme, idx) => {
                                                        return (
                                                            <option value={theme._id}>{theme.name}</option>

                                                        )
                                                    }))
                                                }
                                            </Form.Control>
                                            <small style={{ color: '#db1313' }}> {errors['themeId'] || ''} </small>
                                        </Form.Group>
                                    </Col>

                                    <Col sm={12} md={12}>
                                        <Form.Group>
                                            <Form.Label>Sub Theme</Form.Label>
                                            <Form.Control
                                                as="select"
                                                name="subThemeId"
                                                custom
                                                value={scriptDetail['subThemeId'] || ''}
                                                onChange={this.handleInput}>
                                                <option value="">Select Sub Theme</option>
                                                {
                                                (subThemes.length > 0 &&
                                                    subThemes.map((theme, idx) => {
                                                return (
                                                    <option value={theme._id}>{theme.name}</option>
                                                    )
                                                }))
                                                }
                                            </Form.Control>
                                            <small style={{ color: '#db1313' }}> {errors['subThemeId'] || ''} </small>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12} md={12}>
                                        <Form.Group>
                                            <Form.Label>Felt Need</Form.Label>
                                            <Form.Control as="select" name="feltNeed" custom defaultValue={scriptDetail['feltNeed'] || ''} onChange={this.handleInput}>
                                                <option value="" disabled>Select Felt Need</option>
                                                <option value="Lonely">Lonely</option>
                                                <option value="Empty">Empty</option>
                                                <option value="Broken">Broken</option>
                                                <option value="Shame">Shame</option>
                                                <option value="Angry">Angry</option>
                                                <option value="Fear">Fear</option>
                                                <option value="Happy">Happy</option>
                                                <option value="Sad">Sad</option>
                                                <option value="Surprise">Surprise</option>
                                            </Form.Control>
                                            <small style={{ color: '#db1313' }}> {errors['feltNeed'] || ''} </small>
                                        </Form.Group>
                                    </Col>
                                    {/* <Col sm={12} md={12}>
                                            <Form.Group>
                                                <Form.Label>Theme</Form.Label>
                                                <Form.Control type="text" name="theme" placeholder="Enter Theme Ex. Love" defaultValue={scriptDetail['theme'] || ''} onChange={this.handleInput}/>
                                                <small style={{ color:'#db1313'}}> { errors['theme'] || '' } </small>
                                            </Form.Group>
                                        </Col>
                                        <Col sm={12} md={12}>
                                            <Form.Group>
                                                <Form.Label>Length</Form.Label>
                                                <Form.Control as= "select" name="length" custom defaultValue={scriptDetail['length'] || ''} onChange={this.handleInput}>
                                                    <option value="" disabled> Select Length </option>
                                                    <option value="30">30</option>
                                                    <option value="45">45</option>
                                                    <option value="60">60</option>
                                                    <option value="90">90</option>
                                                    <option value="120">120</option>
                                                </Form.Control>
                                                <small style={{ color:'#db1313'}}> { errors['length'] || '' } </small>
                                            </Form.Group>
                                        </Col> */}
                                    <Col sm={12} md={12}>
                                        <Form.Group>
                                            <Form.Label>Language</Form.Label>
                                            <Form.Control as="select" name="language" custom defaultValue={scriptDetail['language'] || ''} onChange={this.handleInput}>
                                                <option value="" disabled> Select Language </option>
                                                <option value="English" >English</option>
                                                {/* <option value="Germany" >Germany</option> */}
                                                {/* {lang.map((l, idx) => {
                                                        return (
                                                            <option value={l.name} key={idx}>{l.name}</option>
                                                        )
                                                    })} */}
                                            </Form.Control>
                                            <small style={{ color: '#db1313' }}> {errors['language'] || ''} </small>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12} md={12}>
                                        <Form.Group>
                                            <Form.Label>Video Tags</Form.Label>
                                            {(scriptDetail['tags'] && scriptDetail['tags'].length > 0) &&
                                                <ul>
                                                    {scriptDetail['tags'].map((t, i) => (
                                                        <OverlayTrigger
                                                            key={i}
                                                            placement="top"
                                                            overlay={
                                                                <Tooltip id={`tooltip-project`}>
                                                                    Double click to remove
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <li onDoubleClick={() => this.handleRemoveTagOnDoubleClick(t)}>
                                                                {t}
                                                            </li>
                                                        </OverlayTrigger>
                                                    ))}
                                                </ul>
                                            }
                                            <Form.Control type="text" className="form-control mb-1" id="tag" name="tag" aria-describedby="tag" ref={r => this.inputTags = r} placeholder="Enter Tag" onKeyUp={this.handleAddTag} />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12} md={12}>
                                        <Form.Group>
                                            <Form.Label>Script</Form.Label>
                                            <Form.Control as="textarea" rows={7} placeholder="Write Your Script Here..." name="script" defaultValue={scriptDetail['script'] || ''} onChange={this.handleInput} />
                                            <small style={{ color: '#db1313' }}> {errors['script'] || ''} </small>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12} className="d-sm-flex align-items-center justify-content-end">
                                        <Button size="sm" variant="primary" className="text-capitalize mr-2" disabled={!isFormValid || updateLoader} onClick={(e) => this.handleScriptUpdate(e)}> {updateLoader ? 'wait...' : 'Submit'} </Button>
                                        <Button size="sm" variant="outline-secondary" className="text-capitalize" onClick={() => this.handleCloseEditModal()}> Cancel </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Modal.Body>
                    </Modal>
                }
                {showUserModal &&

                    <Modal show={showUserModal} onHide={() => this.setState({ showUserModal: false, uploadedUser: {} })} size="lg" aria-labelledby="contained-modal-title-vcenter" centered backdrop="static">
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                Uploaded User Details
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col sm={12} md={12}>
                                    <Table responsive="xl" className="uploadedBy">
                                        <tbody>
                                            <tr>
                                                <th className="border-top-0"> Full Name </th>
                                                <td className="border-top-0"> {uploadedUser.fullname || 'N/A'} </td>
                                            </tr>
                                            <tr>
                                                <th className="border-top-0"> Gender </th>
                                                <td className="border-top-0">
                                                    {uploadedUser.gender
                                                        ?
                                                        typeof uploadedUser.gender === 'string'
                                                            ?
                                                            uploadedUser.gender.toUpperCase()
                                                            :
                                                            uploadedUser.gender
                                                        :
                                                        'N/A'
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className="border-top-0"> Email </th>
                                                <td className="border-top-0"> {uploadedUser.email || 'N/A'} </td>
                                            </tr>
                                            <tr>
                                                <th className="border-top-0"> Role </th>
                                                <td className="border-top-0">
                                                    {(Array.isArray(uploadedUser.role) && uploadedUser.role.length)
                                                        ?
                                                        uploadedUser.role.map((r, urid) => {
                                                            if (r) {
                                                                return <span key={urid}> {typeof r.name === 'string' ? r.name.toUpperCase() : r.name} </span>
                                                            }
                                                        })
                                                        :
                                                        'N/A'
                                                    }
                                                </td>
                                            </tr>
                                            <tr >
                                                <th className="border-top-0"> Registered Date </th>
                                                <td className="border-top-0">
                                                    {uploadedUser.createdAt
                                                        ?
                                                        (new Date(uploadedUser.createdAt.split('T')[0])).toLocaleString('en', { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' })
                                                        :
                                                        'N/A'
                                                    }
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
                }

                {showAddScriptModal &&
                    <Modal show={showAddScriptModal} onHide={() => this.setState({ showAddScriptModal: false })} size="lg" aria-labelledby="contained-modal-title-vcenter" centered backdrop="static">
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                Add New Script
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Row>
                                    <Col sm={12} md={12}>
                                        <Form.Group>
                                            <Form.Label>Title</Form.Label>
                                            <Form.Control type="text" name="title" placeholder="Enter Title Ex. Love" defaultValue={addScriptData['title'] || ''} onChange={this.handleInput_addScript} />
                                            <small style={{ color: '#db1313' }}> {errors['title'] || ''} </small>
                                        </Form.Group>
                                    </Col>
                                    {/* <Col sm={12} md={12}>
                                        <Form.Group>
                                            <Form.Label>Theme</Form.Label>
                                            <Form.Control type="text" name="theme" placeholder="Enter Theme Ex. Love" defaultValue={addScriptData['theme'] || ''} onChange={this.handleInput_addScript}/>
                                            <small style={{ color:'#db1313'}}> { errors['theme'] || '' } </small>
                                        </Form.Group>
                                    </Col> */}
                                    <Col sm={12} md={12}>
                                        <Form.Group>
                                            <Form.Label>Theme</Form.Label>
                                            <Form.Control as="select" name="themeId" custom defaultValue={addScriptData['themeId'] || ''} onChange={this.handleInput_addScript}>
                                                <option value="" disabled>Select Theme</option>
                                                {
                                                    (themes.length > 0 && themes.map((theme, idx) => {
                                                        return (
                                                            <option value={theme._id}>{theme.name}</option>

                                                        )
                                                    }))
                                                }
                                            </Form.Control>
                                            <small style={{ color: '#db1313' }}> {errors['themeId'] || ''} </small>
                                        </Form.Group>
                                    </Col>

                                    <Col sm={12} md={12}>
                                        <Form.Group>
                                            <Form.Label>Sub Theme</Form.Label>
                                            <Form.Control as="select" name="subThemeId" custom defaultValue={addScriptData['subThemeId'] || ''} onChange={this.handleInput_addScript}>
                                                <option value="" disabled>Select Sub Theme</option>
                                                {
                                                    (subThemes.length > 0 && subThemes.map((theme, idx) => {
                                                        return (
                                                            <option value={theme._id}>{theme.name}</option>
                                                        )
                                                    }))
                                                }
                                            </Form.Control>
                                            <small style={{ color: '#db1313' }}> {errors['subThemeId'] || ''} </small>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12} md={12}>
                                        <Form.Group>
                                            <Form.Label>Felt Need</Form.Label>
                                            <Form.Control as="select" name="feltNeed" custom defaultValue={addScriptData['feltNeed'] || ''} onChange={this.handleInput_addScript}>
                                                <option value="" disabled>Select Felt Need</option>
                                                <option value="Lonely">Lonely</option>
                                                <option value="Empty">Empty</option>
                                                <option value="Broken">Broken</option>
                                                <option value="Shame">Shame</option>
                                                <option value="Angry">Angry</option>
                                                <option value="Fear">Fear</option>
                                                <option value="Happy">Happy</option>
                                                <option value="Sad">Sad</option>
                                                <option value="Surprise">Surprise</option>
                                            </Form.Control>
                                            <small style={{ color: '#db1313' }}> {errors['feltNeed'] || ''} </small>
                                        </Form.Group>
                                    </Col>
                                    {/* <Col sm={12} md={12}>
                                        <Form.Group>
                                            <Form.Label>Length</Form.Label>
                                            <Form.Control as= "select" name="length" custom defaultValue={addScriptData['length'] || ''} onChange={this.handleInput_addScript}>
                                                <option value="" disabled> Select Length </option>
                                                <option value="30">30</option>
                                                <option value="45">45</option>
                                                <option value="60">60</option>
                                                <option value="90">90</option>
                                                <option value="120">120</option>
                                            </Form.Control>
                                            <small style={{ color:'#db1313'}}> { errors['length'] || '' } </small>
                                        </Form.Group>
                                    </Col> */}
                                    <Col sm={12} md={12}>
                                        <Form.Group>
                                            <Form.Label>Language</Form.Label>
                                            <Form.Control as="select" name="language" custom defaultValue={addScriptData['language'] || ''} onChange={this.handleInput_addScript}>
                                                <option value="" disabled> Select Language </option>
                                                <option value="English" >English</option>

                                            </Form.Control>
                                            <small style={{ color: '#db1313' }}> {errors['language'] || ''} </small>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12} md={12}>
                                        <Form.Group>
                                            <Form.Label>Video Tags</Form.Label>
                                            {(addScriptData['tags'] && addScriptData['tags'].length > 0) &&
                                                <ul>
                                                    {addScriptData['tags'].map((t, i) => (
                                                        <OverlayTrigger
                                                            key={i}
                                                            placement="top"
                                                            overlay={
                                                                <Tooltip id={`tooltip-project`}>
                                                                    Double click to remove
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <li onDoubleClick={() => this.addScript_handleRemoveTagOnDoubleClick(t)}>
                                                                {t}
                                                            </li>
                                                        </OverlayTrigger>
                                                    ))}
                                                </ul>
                                            }
                                            <Form.Control type="text" className="form-control mb-1" id="tag" name="tag" aria-describedby="tag" ref={r => this.addScriptInputTags = r} placeholder="Enter Tag" onKeyUp={this.addScript_handleAddTag} />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12} md={12}>
                                        <Form.Group>
                                            <Form.Label>Script</Form.Label>
                                            <Form.Control as="textarea" rows={7} placeholder="Write Your Script Here..." name="script" defaultValue={addScriptData['script'] || ''} onChange={this.handleInput_addScript} />
                                            <small style={{ color: '#db1313' }}> {errors['script'] || ''} </small>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12} className="d-sm-flex align-items-center justify-content-end">
                                        <Button size="sm" variant="primary" className="text-capitalize mr-2" disabled={!isAddScriptFormValid || addScriptLoader} onClick={(e) => this.handleAddNewScript(e)}> {addScriptLoader ? 'wait...' : 'Submit'} </Button>
                                        <Button size="sm" variant="outline-secondary" className="text-capitalize" onClick={() => this.handleResetAddScriptForm()}> Cancel </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Modal.Body>
                    </Modal>


                }

                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    {/* <h1 className="h3 mb-0 text-gray-800">List Users</h1> */}
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="./">Dashboard</a></li>
                        <li className="breadcrumb-item">Scripts</li>
                        <li className="breadcrumb-item active" aria-current="page"> List Scripts </li>
                    </ol>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card mb-4">
                            <div className="card-header px-3 d-lg-flex flex-row align-items-center justify-content-between">
                                <h6 className="m-0 mb-3 mb-lg-0"><strong>List Scripts</strong></h6>
                                <div className="d-md-flex flex-row align-items-center justify-content-between">
                                    <div className='seclect-script-download'>
                                        {this.state.scriptDbDowload?.length ?
                                        <div class="dropdown">
                                            <button class="text-capitalize mr-2 btn btn-primary btn-sm dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Download Script
                                            </button>
                                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                <a class="dropdown-item">
                                                    <span onClick={() => this.downloadAsPDFZip(this.state.scriptDbDowload)}>
                                                        <svg viewBox="0 0 512 512" >
                                                            <path d="M444.875,109.792L338.208,3.125c-2-2-4.708-3.125-7.542-3.125h-224C83.135,0,64,19.135,64,42.667v426.667     C64,492.865,83.135,512,106.667,512h298.667C428.865,512,448,492.865,448,469.333v-352     C448,114.5,446.875,111.792,444.875,109.792z M341.333,36.417l70.25,70.25h-48.917c-11.76,0-21.333-9.573-21.333-21.333V36.417z      M426.667,469.333c0,11.76-9.573,21.333-21.333,21.333H106.667c-11.76,0-21.333-9.573-21.333-21.333V42.667     c0-11.76,9.573-21.333,21.333-21.333H320v64C320,108.865,339.135,128,362.667,128h64V469.333z" />
                                                            <path d="M310.385,313.135c-9.875-7.771-19.26-15.76-25.51-22.01c-8.125-8.125-15.365-16-21.656-23.5     c9.813-30.323,14.115-45.958,14.115-54.292c0-35.406-12.792-42.667-32-42.667c-14.594,0-32,7.583-32,43.688     c0,15.917,8.719,35.24,26,57.698c-4.229,12.906-9.198,27.792-14.781,44.573c-2.688,8.052-5.604,15.51-8.688,22.406     c-2.51,1.115-4.948,2.25-7.302,3.427c-8.479,4.24-16.531,8.052-24,11.594C150.5,370.177,128,380.844,128,401.906     c0,15.292,16.615,24.76,32,24.76c19.833,0,49.781-26.49,71.656-71.115c22.708-8.958,50.938-15.594,73.219-19.75     c17.854,13.729,37.573,26.865,47.125,26.865c26.448,0,32-15.292,32-28.115c0-25.219-28.813-25.219-42.667-25.219     C337.031,309.333,325.49,310.604,310.385,313.135z M160,405.333c-6.094,0-10.219-2.875-10.667-3.427     c0-7.563,22.552-18.25,44.365-28.583c1.385-0.656,2.792-1.313,4.219-1.99C181.896,394.563,166.052,405.333,160,405.333z      M234.667,214.354c0-22.354,6.938-22.354,10.667-22.354c7.542,0,10.667,0,10.667,21.333c0,4.5-3,15.75-8.49,33.313     C239.135,233.75,234.667,222.698,234.667,214.354z M242.844,329c0.667-1.854,1.313-3.729,1.938-5.625     c3.958-11.875,7.521-22.542,10.698-32.146c4.427,4.875,9.198,9.865,14.313,14.979c2,2,6.958,6.5,13.563,12.135     C270.208,321.208,256.219,324.76,242.844,329z M362.667,334.552c0,4.792,0,6.781-9.896,6.844     c-2.906-0.625-9.625-4.583-17.917-10.229c3.01-0.333,5.229-0.5,6.479-0.5C357.094,330.667,361.563,332.208,362.667,334.552z" />
                                                        </svg>
                                                    </span>
                                                </a>
                                                <a class="dropdown-item">
                                                <span onClick={() => this.downloadDocFileZip(this.state.scriptDbDowload)}>
                                                        <svg viewBox="0 0 512 512">
                                                            <path d="M282.208,19.67c-3.648-3.008-8.48-4.256-13.152-3.392l-256,48C5.472,65.686,0,72.278,0,79.99v352    c0,7.68,5.472,14.304,13.056,15.712l256,48c0.96,0.192,1.984,0.288,2.944,0.288c3.68,0,7.328-1.28,10.208-3.68    c3.68-3.04,5.792-7.584,5.792-12.32v-448C288,27.222,285.888,22.71,282.208,19.67z M256,460.694L32,418.71V93.27l224-41.984    V460.694z" />
                                                            <path d="M496,79.99H272c-8.832,0-16,7.168-16,16c0,8.832,7.168,16,16,16h208v288H272c-8.832,0-16,7.168-16,16    c0,8.832,7.168,16,16,16h224c8.832,0,16-7.168,16-16v-320C512,87.158,504.832,79.99,496,79.99z" />
                                                            <path d="M432,143.99H272c-8.832,0-16,7.168-16,16c0,8.832,7.168,16,16,16h160c8.832,0,16-7.168,16-16    C448,151.158,440.832,143.99,432,143.99z" />
                                                            <path d="M432,207.99H272c-8.832,0-16,7.168-16,16c0,8.832,7.168,16,16,16h160c8.832,0,16-7.168,16-16    C448,215.158,440.832,207.99,432,207.99z" />
                                                            <path d="M432,271.99H272c-8.832,0-16,7.168-16,16c0,8.832,7.168,16,16,16h160c8.832,0,16-7.168,16-16    C448,279.158,440.832,271.99,432,271.99z" />
                                                            <path d="M432,335.99H272c-8.832,0-16,7.168-16,16c0,8.832,7.168,16,16,16h160c8.832,0,16-7.168,16-16    C448,343.158,440.832,335.99,432,335.99z" />
                                                            <path d="M209.76,176.086c-8.48-0.864-16.704,5.344-17.664,14.112l-8.608,77.504l-24.512-65.344    c-4.704-12.48-25.312-12.48-29.984,0l-26.016,69.408l-7.136-50.048c-1.248-8.768-9.44-14.976-18.112-13.568    c-8.736,1.248-14.816,9.344-13.568,18.08l16,112c1.024,7.264,6.848,12.896,14.112,13.664c7.424,0.736,14.144-3.424,16.704-10.272    L144,253.558l33.024,88.064c2.368,6.272,8.384,10.368,14.976,10.368c0.672,0,1.312-0.032,1.984-0.16    c7.328-0.896,13.088-6.752,13.92-14.08l16-144C224.864,184.982,218.56,177.078,209.76,176.086z" />
                                                        </svg>
                                                    </span>
                                                </a>
                                                {/* <a class="dropdown-item">
                                                    <span onClick={() => this.downloadTxtFileZip(this.state.scriptDbDowload)}>
                                                        <svg viewBox="0 0 24 24">
                                                            <g><path d="m12.25 12c-.414 0-.75-.336-.75-.75v-.75h-7v.75c0 .414-.336.75-.75.75s-.75-.336-.75-.75v-1.5c0-.414.336-.75.75-.75h8.5c.414 0 .75.336.75.75v1.5c0 .414-.336.75-.75.75z" /></g>
                                                            <g><path d="m8 18.75c-.414 0-.75-.336-.75-.75v-8c0-.414.336-.75.75-.75s.75.336.75.75v8c0 .414-.336.75-.75.75z" /></g>
                                                            <g><path d="m9.25 19h-2.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h2.5c.414 0 .75.336.75.75s-.336.75-.75.75z" /></g>
                                                            <g><path d="m20.25 10.5h-4.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h4.5c.414 0 .75.336.75.75s-.336.75-.75.75z" /></g>
                                                            <g><path d="m20.25 14.5h-4.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h4.5c.414 0 .75.336.75.75s-.336.75-.75.75z" /></g>
                                                            <g><path d="m20.25 18.5h-4.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h4.5c.414 0 .75.336.75.75s-.336.75-.75.75z" /></g>
                                                            <g><path d="m21.25 23h-18.5c-1.517 0-2.75-1.233-2.75-2.75v-16.5c0-1.517 1.233-2.75 2.75-2.75h18.5c1.517 0 2.75 1.233 2.75 2.75v16.5c0 1.517-1.233 2.75-2.75 2.75zm-18.5-20.5c-.689 0-1.25.561-1.25 1.25v16.5c0 .689.561 1.25 1.25 1.25h18.5c.689 0 1.25-.561 1.25-1.25v-16.5c0-.689-.561-1.25-1.25-1.25z" /></g>
                                                            <g><path d="m23.25 6h-22.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h22.5c.414 0 .75.336.75.75s-.336.75-.75.75z" /></g>
                                                        </svg>
                                                    </span>
                                                </a> */}
                                            </div>
                                        </div>
                                            : null}
                                    </div>
                                    <div className="table-entries mx-lg-2 my-3 my-md-0 d-flex flex-row align-items-center justify-content-between">
                                        <Button size="sm" variant="primary" className="text-capitalize mr-2" onClick={() => this.setState({ showAddScriptModal: true })}> Add Script </Button>
                                    </div>
                                    <Form inline>
                                        <Form.Label className="mr-2" htmlFor="inlineFormCustomSelectPref">
                                            Search
                                        </Form.Label>
                                        <Form.Control type="text" size="sm" placeholder="title/theme/language/tags" onChange={this.handleSearchInput} value={searchInput} />
                                    </Form>
                                    <div className="table-entries mx-lg-2 my-3 my-md-0 d-flex flex-row align-items-center justify-content-between">
                                        <span> Show </span>
                                        <Form.Control as="select" className="mx-2" size="sm" refs="perPpage" defaultValue={perPage} selected={perPage} onChange={(e) => this.onChangeEntries(e)} custom>
                                            <option value="5"> 5 Entries </option>
                                            <option value="10"> 10 Entries </option>
                                            <option value="20"> 20 Entries</option>
                                            <option value="30"> 30 Entries</option>
                                            <option value="50"> 50 Entries</option>
                                            <option value="100"> 100 Entries</option>
                                        </Form.Control>
                                    </div>
                                    <div className="table-entries mx-lg-2 my-3 my-md-0 d-flex flex-row align-items-center justify-content-between">
                                        <span onClick={() => this.refreshData()} style={{ cursor: 'pointer' }}>
                                            {refreshLoading
                                                ?
                                                <><Spinner animation="border" variant="dark" size="sm" /> Wait... </>
                                                :
                                                <> <i className="fa fa-refresh"></i> Refresh </>
                                            }
                                        </span>
                                    </div>
                                </div>
                            </div>
                            {loader
                                ?
                                <div className="text-center mt-3 mb-3"><Spinner as="span" animation="border" size="sm" role="status" /></div>
                                :
                                (scripts && scripts.length > 0)
                                    ?
                                    <Table responsive>
                                        <thead>
                                            <tr>
                                                <th>
                                                S.No.
                                                </th>
                                                <th>Title</th>
                                                <th>Theme</th>
                                                {/* <th>Length</th> */}
                                                <th>Sub Theme</th>
                                                <th>Language</th>
                                                <th>Tags</th>
                                                <th>Uploaded By</th>
                                                <th>Date of Uploading</th>
                                                <th>Last Updated</th>
                                                <th>Script</th>
                                                <th style={{ textAlign: 'center' }}>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                (currentResults.length ? currentResults : scripts).map((script, idx) => {
                                                    return (
                                                        
                                                        <tr key={idx}>
                                                            {/* <th scope="row"> {(page) * 10 + (idx+1)} </th> */}
                                                            <th scope="row">
                                                                <span>
                                                                <input type="checkbox" id="checkbox" value={script}
                                                                checked = {this.state.scriptDbDowload.includes(script)}
                                                                onChange={(e) => this.handleChangeDownloadmultiScript(e, script)} />
                                                                </span>
                                                                { " " }
                                                                 {perPage * (page) + idx + 1} </th>
                                                            <td> {script.title || 'N/A'} </td>
                                                            <td> {script.themeId ? script.themeId.name || 'N/A' : 'N/A'} </td>
                                                            <td> {script.subThemeId ? script.subThemeId.name || 'N/A' : 'N/A'} </td>
                                                            <td> {script.language || 'N/A'} </td>
                                                            <td> {script.tags.length > 0 ? script.tags.slice(0, 5).join(', ') + "..." : 'N/A'} </td>
                                                            <td>
                                                                <OverlayTrigger

                                                                    placement="auto"
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-user_uploaded_${idx}`}>
                                                                            {script.uploadedBy ? ' Click to see ' + script.uploadedBy.fullname + ' details ' : ''}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <Link to="#" onClick={() => script.uploadedBy ? this.setState({ showUserModal: true, uploadedUser: script.uploadedBy }) : null}>
                                                                        {script.uploadedBy ? script.uploadedBy.fullname : 'N/A'}
                                                                    </Link>
                                                                </OverlayTrigger>

                                                            </td>
                                                            <td>
                                                                {script.createdAt ? (new Date(script.createdAt.split('T')[0])).toLocaleString('en', { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }) : 'N/A'}
                                                            </td>
                                                            <td>
                                                                {script.updatedAt ? (new Date(script.updatedAt.split('T')[0])).toLocaleString('en', { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }) : 'N/A'}
                                                            </td>
                                                            <td>
                                                                <OverlayTrigger
                                                                    placement="auto"
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-script_view_${idx}`}>
                                                                            Click here to view script
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <Button variant="outline-primary" size="sm" onClick={() => this.handleViewOnScript(script)}>View</Button>
                                                                </OverlayTrigger>
                                                            </td>
                                                            <td className="list-script-actions" >
                                                                <span>
                                                                    <OverlayTrigger
                                                                        placement="auto"
                                                                        overlay={
                                                                            <Tooltip id={`tooltip-script_edit_${idx}`}>
                                                                                Edit Script
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <i className="fa fa-pencil" style={{ cursor: 'pointer' }} onClick={() =>
                                                                            this.handleEditScript(script)} />
                                                                    </OverlayTrigger>
                                                                </span>
                                                                <span>
                                                                    <OverlayTrigger
                                                                        placement="auto"
                                                                        overlay={
                                                                            <Tooltip id={`tooltip-script_del_${idx}`}>
                                                                                Delete Script
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <i className="fa fa-trash-o" style={{ cursor: 'pointer' }} onClick={() => this.handleDeleteScript(script._id)} />
                                                                    </OverlayTrigger>
                                                                </span>
                                                                <span>
                                                                    <OverlayTrigger
                                                                        placement="auto"
                                                                        overlay={
                                                                            <Tooltip id={`tooltip-script_send_${idx}`}>
                                                                                Send For Translation
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <i className="fa fa-send" style={{ cursor: 'pointer' }} onClick={() => window.location.assign(`${ENV.translateApp}/r-project/${script._id}/${token}`)} />
                                                                    </OverlayTrigger>
                                                                </span>

                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                    :
                                    <p className="mt-3 mb-3 text-center" style={{ textTransform: 'uppercase' }}>  Nothing to show </p>
                            }
                            {(scripts.length > 0 && pageNumbers > 1) &&
                                <section className="pagination my-3">
                                    <ReactPaginate
                                        previousLabel={'<<'}
                                        nextLabel={'>>'}
                                        breakLabel={'...'}
                                        breakClassName={'break-me'}
                                        pageCount={pageNumbers}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={3}
                                        onPageChange={paginate}
                                        containerClassName={'pagination'}
                                        subContainerClassName={'pages pagination'}
                                        activeClassName={'active'}
                                    />
                                </section>
                            }
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = state => {

    const { script, theme } = state
    return {
        scripts: script.getScripts,
        delScript: script.delScript,
        updScript: script.updateScript,
        addedScript: script.add,
        themes: theme.getThemes
    }
}

const dispatchActions = { getScripts, deleteScript, updateScript, addScript, getThemes }

export default connect(mapStateToProps, dispatchActions)(ListScript);