import React from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import { isLoggedIn } from '../helpers/decode-token';

// --------------------- Public Routes --------------------- //
import NotFound from '../components/Errors/404'
// --------------------- Public Routes --------------------- //

// --------------------- Private Routes -------------------- //
import Dashboard from '../components/Dashboard/Dashboard';
import ListUsers from '../components/UserManagement/ListUsers';
import ListScripts from '../components/ScriptManagement/ListScripts';
import MyProfile from '../components/MyProfile/MyProfile';
import ListThemes from '../components/ThemeManagement/ListThemes';
import UploadVideoComponent from '../components/VideoManagement/UploadVideoComponent';
import VideoListComponent from '../components/VideoManagement/VideoListComponent';
import FileNameGrenrator from '../components/FileNameMangement/FileNameGenrator';
// import ListFileNames from '../components/fileNameMangement/ListFileNames';
import ListFileNames from '../components/FileNameMangement/ListFileNames';

// --------------------- Private Routes -------------------- //


// --- Error Page --- //
toast.configure()
const ROUTES = [{
    path: '/',
    key: "DASHBOARD",
    component: props => {
        if (!isLoggedIn) {
            return <Redirect to="/login" />
        }
        return <RenderRoutes {...props}
        />
    },
    routes: [
        { path: "/", key: "DASHBOARD_INDEX", exact: true, component: Dashboard },
        { path: '/list-users', key: "LIST_USERS", exact: true, component: ListUsers },
        { path: '/list-scripts', key: "LIST_SCRIPTS", exact: true, component: ListScripts },
        { path: '/my-profile', key: 'MY_PROFILE', exact: true, component: MyProfile },
        { path: '/list-themes', key: "LIST_THEMES", exact: true, component: ListThemes },
        { path: '/video-upload', key: 'VIDEO_UPLOAD', exact: true, component: UploadVideoComponent },
        { path: '/video-list', key: 'VIDEO_List', exact: true, component: VideoListComponent },
        { path: '/genrate-video-filename', key: 'genrate-video-filename', exact: true, component: FileNameGrenrator },
        {path: '/list-video-filenames' , key: 'list-video-filenames' , exact:true , component:ListFileNames}

    ]
}];
export default ROUTES;

function RouteWithSubRoutes(route) {
    return (<
        Route path={route.path}
        exact={route.exact}
        render={
            props => < route.component {...props}
                routes={route.routes}
            />} />
    );
}


export function RenderRoutes({ routes }) {
    return (<
        Switch > {
            routes.map((route, i) => {
                return <RouteWithSubRoutes key={route.key} {...route}
                />;
            })
        } <
            Route render={props => < NotFound />}
        /> </Switch>
    );
}