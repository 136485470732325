import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Form, Button , Col , Row  , Table } from 'react-bootstrap';
import { getThemes} from '../../actions/themeActions';
import { faceBookPageList , countryLanguageData , Stages } from '../../helpers/Content';
import { createVideoFilename} from '../../actions/scriptActions';
import DatePicker from "react-datepicker";
import Modal from 'react-responsive-modal';
import '../../App.css';
import "react-datepicker/dist/react-datepicker.css";

class  FileNameGrenrator extends Component {
    constructor(props) {
        super(props);
        this.state= {
            fields :{
                facebookPage: '' ,
                countryCode: '' , 
                languageCode : '' , 
                date : new Date() , 
                localization : '' , 
                videoTitle : '' , 
                theme : '' , 
                subTheme: '' , 
                stage : '' , 
                subStage : '' , 
                poNumber : '' ,
                test : '',
            },
            languages : [],
            themes : [],
            subThemes : [] ,
            loading : false , 
            formError : false ,
            fileConventionName : '' , 
            subStages : [] , 
            fbPages : [] ,
            errors: {} ,
            fileNameFirst: '',
            fileNameSecond:'',
            fileNameThird: '',
            campaignName : '' ,
            showModel : false ,
        }
    }

    componentDidMount = async () => {
        await this.handleFetchThemes();
        this.addcommanLanguage();
    }


    addcommanLanguage=()=>{
          for (let i = 0; i < countryLanguageData.length; i++) {
            let englishExists = false; 
            for (let j = 0; j < countryLanguageData[i]["language"].length; j++) {
              if (countryLanguageData[i]["language"][j]["languageCode"] === "EN") {
                englishExists = true;
                break;
              }
            }
            // if English language does not exist, add a new language object to the array with English values
            if (!englishExists) {
                countryLanguageData[i]["language"].push({"languageName": "(EN) English", "languageCode": "EN"});
            }
          }
    }

    handleFetchThemes = async () => {
        this.setState({loading : true})
        await this.props.getThemes();
        if (this.props.themes.response) {
          this.setState({loading : false})
            this.setState({
                themes: this.props.themes.themes,
               
            })
        } else {
            this.setState({loading : false })
            toast.dismiss()
            toast.error(this.props.themes.message, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true })
        }
    }

    handleChange = (e) =>{
      let {fields} = this.state ; 
      const {value , name} = e.target ;
      fields = ({...fields , [name]:value})
      this.setState({fields})
       if (name ==='countryCode'){
          const language = countryLanguageData.filter ((item,idx) =>{
            if ( item.countryCode ===  e.target.value){
                return (
                  item
                )
            }
          })
            
          this.setState({ languages : language[0].language});
          const fbPages =  faceBookPageList.filter((item , ind)=>{
              if(item.countryCode === e.target.value){
                  return (
                      item
                  )
              }
          })
          this.setState({fbPages : fbPages[0].pages})
        }
        if(name === 'theme'){
       
          const Theme = this.state.themes.filter((item , idx)=>{
            if(item.name === e.target.value){
              return (
                item
              )
            }
          })
          this.setState({subThemes: Theme[0].subthemes})
        }

        if(name === 'stage'){
            const subStages = Stages.filter((item , idx)=>{
              if (item.stageName === e.target.value){
                return (
                  item
                )
              }
            })
          this.setState({subStages : subStages[0].subStages})
        }

    }

    subDays = () => {
        var d = new Date();
        return d;
    }


    validate=(values)=>{
        let errors = {};
        let isValid=true;
        if(!values.facebookPage){
            errors.facebookPage="facebookPage  name  is required!"
            isValid = false
          }
          if(!values.countryCode){
            errors.countryCode="country is required!"
            isValid = false
          }
          if(!values.languageCode){
            errors.languageCode="languageCode is required"
            isValid = false
          }
          if(!values.date){
            errors.date="date is required"
            isValid = false
          }
          if(!values.localization){
            errors.localization="localization is required"
            isValid = false
          }
          if(!values.videoTitle){
            errors.videoTitle="video Title is required"
            isValid = false
          }
          if(!values.theme){
            errors.theme="theme is required"
            isValid = false
          }
          if(!values.subTheme){
            errors.subTheme="subTheme is required"
            isValid = false
          }
          if(!values.test){
            errors.test="test  is required"
            isValid = false
          }
          if(!values.stage){
            errors.stage="stage is required"
            isValid = false
          }
          // if(!values.subStage){
          //   errors.subStage="subStage is required"
          //   isValid = false
          // }
          if(!values.poNumber){
            errors.poNumber=" PO  code  is required"
            isValid = false
          }
          this.setState({errors : errors})
          return isValid;
    }


    showFileNames = () =>{
      const {campaignName ,  fileNameFirst ,  fileNameSecond , fileNameThird} = this.state ;
      return (
          <div className="well m-4">
           <div className="card m-2">
              <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                  <h6 className="m-0 font-weight-bold text-danger"> Names </h6>
              </div>
              <div className="card-body">
                  <Table bordered  size="sm">
                    <tbody>
                        <tr>
                            <th> campaign Name</th>
                        </tr>
                        <tr>
                           <td> { campaignName  } </td>
                        </tr>
                        <tr>
                           <th> File Names </th>
                        </tr>
                        <tr>    
                            <td>{fileNameFirst} </td>
                        </tr>
                        <tr>    
                            <td>{fileNameSecond} </td>
                        </tr>
                        <tr>    
                            <td>{fileNameThird} </td>
                        </tr>
                    </tbody>
                  </Table>
                  <div className='m-2'>
                    <Button onClick = {this.saveFileNames}>{this.state.loading ? 'please wait ' : 'Save'}</Button>
                  </div>
              </div>
            </div>  
          </div>

        )

    }


    saveFileNames = async() =>{
      const {campaignName ,fileNameFirst , fileNameSecond , fileNameThird} = this.state
      this.setState({loading: true});

      const fileNames = {
        campaignName: campaignName ,
        videoFilename : {fileNameFirst:fileNameFirst ,fileNameSecond:fileNameSecond,fileNameThird:fileNameThird,}
      }

      await this.props.createVideoFilename({ data:fileNames}) ; 
        if(this.props.videoFilename.success){
            this.setState({loading : false})
            this.resetState()
            this.props.history.push('/list-video-filenames')
        }else{
            this.setState({loading : false })
            toast.dismiss();
            toast.error(this.props.videoFilename.message, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true })
        }
    }
    

    generateNamingConvention = async (e) =>{
        e.preventDefault() ; 
        const {fields , subStages} = this.state ; 
        if(this.validate(fields)){
            this.setState({loading : true})
            const facebookPageName = fields['facebookPage'].toUpperCase().replace(/\s/g, '');
            const countryCode = fields['countryCode'].toUpperCase().replace(/\s/g, '');
            const languageCode = fields['languageCode'].toUpperCase().replace(/\s/g, '');
            const originalOrLocalized = fields['localization'].charAt(0);
            // const videoTitle = fields['videoTitle'].toUpperCase();
            const theme = fields['theme'].toUpperCase().replace(/\s/g, '');
            const subTheme = fields['subTheme'].toUpperCase().replace(/\s/g, '');
            // const testStage = fields['stage'].toUpperCase().replace(/\s/g, '');
            // const subStage = fields['subStage'].toUpperCase();
            const poNumber = fields['poNumber'].toUpperCase().replace(/\s/g, '');
            const test = fields['test']
            const currentDate = fields.date.getDate().toString().padStart(2, '0')
            + (fields.date.getMonth() + 1).toString().padStart(2, '0')
            + fields.date.getFullYear().toString().slice(-2);
            const campaign = `${countryCode}-${languageCode}-${currentDate}-${originalOrLocalized}*${facebookPageName}*-${theme}-${subTheme}-Test ${test}-${subStages[0]}--VV -GP - ${poNumber}`
            const fileNameOne = `${countryCode}-${languageCode}-${currentDate}-${originalOrLocalized}*${facebookPageName}*-${theme}-${subTheme}-Test ${test}-${subStages[0]}--VV`
            const fileNameTwo = `${countryCode}-${languageCode}-${currentDate}-${originalOrLocalized}*${facebookPageName}*-${theme}-${subTheme}-Test ${test}-${subStages[1]}--VV`
            const fileNameThree = `${countryCode}-${languageCode}-${currentDate}-${originalOrLocalized}*${facebookPageName}*-${theme}-${subTheme}-Test ${test}-${subStages[2]}--VV`
            this.setState({campaignName : campaign , fileNameFirst:fileNameOne , fileNameSecond:fileNameTwo , fileNameThird: fileNameThree })
            this.setState({showModel:true , loading : false})
            this.resetState()

         
        }
    }

    resetState = () =>{

      const fields ={
        facebookPage: '' ,
        countryCode: '' , 
        languageCode : '' , 
        date : new Date() , 
        localization : '' , 
        videoTitle : '' , 
        theme : '' , 
        subTheme: '' , 
        stage : '' , 
        subStage : '' , 
        poNumber : '' ,
        test : '',
      }  
      this.setState({
        languages : [],
        themes : [],
        subThemes : [] ,
        subStages : [] , 
        fbPages : [] ,
      })
      this.setState({fields:fields})
    }



    closeModel = () =>{
      this.setState({showModel:false})
      this.resetState()
    }

    render(){
        const {fields , languages , themes , subThemes , fbPages , errors} = this.state ; 
        return(
            <>
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="./">Dashboard</a></li>
                        <li className="breadcrumb-item">Themes</li>
                        <li className="breadcrumb-item active" aria-current="page"> Genrate Filename </li>
                    </ol>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card mb-4">
                            <div className="card-header px-3 d-lg-flex flex-row align-items-center justify-content-between">
                                <h3 className="m-0 mb-3 mb-lg-0"><strong>Genrate File Name</strong></h3>
                                <Button onClick={()=>this.props.history.push('/list-video-filenames')}>Show List</Button>
                            </div>
                        </div>    
                    </div>
                </div>    
                <div className="new-form-m">
                <Form onSubmit = {this.generateNamingConvention} >
                  <Row>

                    <Col sm={12} md={6} className='date-section-m'>
                        <Form.Group controlId="formBasicName">
                            <Form.Label> Date </Form.Label>
                              <DatePicker className="date-deu form-control" selected={fields.date} onChange={date => {
                                fields.date = date
                                this.setState(fields)}}
                                minDate={this.subDays}
                                dateFormat="MM/dd/yyyy"
                              />  
                              <i class="far fa-calendar-alt"></i>
                        </Form.Group>
                    </Col>
                    <Col sm={12} md={6}>
                        <Form.Group controlId="formBasicName">
                            <Form.Label>Country</Form.Label>
                            <select name="countryCode" className='form-control' onChange = {this.handleChange} >
                                <option value = '' > Select Country  </option>
                                {countryLanguageData.map((item)=>{
                                return (
                                    <option value={item.countryCode}> {item.countryName}</option>
                                )
                                })}
                            </select>
                        </Form.Group>
                        {errors.countryCode && <span><p style={{"color":"red"}}>{errors.countryCode}</p></span>}
                    </Col>
                    { fields.countryCode !== '' &&  languages?.length > 0 &&
                      <Col sm={12} md={6}>
                        <Form.Group controlId="formBasicName">
                            <Form.Label> Language </Form.Label>
                            <select name="languageCode" className='form-control' onChange = {this.handleChange} >
                              <option value = '' > Select Language  </option>
                             {
                              languages.map((item , idx)=>{
                                return(
                                  <option value = {item.languageCode} key = {idx}> {item.languageName}</option>
                                )
                              })
                            }             
                            </select>
                        </Form.Group>
                        {errors.languageCode && <span><p style={{"color":"red"}}>{errors.languageCode}</p></span>}
                      </Col>
                    }
                    { fields.countryCode !== '' &&  fbPages?.length > 0 &&
                        <Col sm={12} md={6}>
                        <Form.Group controlId="formBasicName">
                            <Form.Label> FB Pages </Form.Label>
                            <select name="facebookPage" className='form-control' onChange = {this.handleChange} >
                              <option value = '' > Select FB Page  </option>
                             {
                              fbPages.map((item , idx)=>{
                                return(
                                  <option value = {item} key = {idx}> {item}</option>
                                )
                              })
                            }             
                            </select>
                        </Form.Group>
                        {errors.facebookPage && <span><p style={{"color":"red"}}>{errors.facebookPage}</p></span>}
                      </Col>
                    }
                    <Col sm={12} md={6}>
                      <Form.Group controlId="formBasicName">
                          <Form.Label> Localization </Form.Label>
                          <select name="localization" className='form-control' value = { fields.localization} onChange={this.handleChange}>
                             <option value = '' > Select anyone  </option>
                             <option value="Original (O)"> Original (O) </option>  
                             <option value = "Localized (L)"> Localized (L) </option>   
                          </select>
                      </Form.Group>
                      {errors.localization && <span><p style={{"color":"red"}}>{errors.localization}</p></span>}
                    </Col>
                    <Col sm={12} md={6}>
                      <Form.Group controlId="formBasicName">
                          <Form.Label> Video Title </Form.Label>
                          <Form.Control name = "videoTitle" value={fields.videoTitle} onChange= {this.handleChange} />
                      </Form.Group>
                      {errors.videoTitle && <span><p style={{"color":"red"}}>{errors.videoTitle}</p></span>}
                    </Col>
                    {themes?.length > 0 &&
                        <Col sm={12} md={6}>
                        <Form.Group controlId="formBasicName">
                            <Form.Label> Theme</Form.Label>
                            <select name = "theme" className='form-control' onChange = {this.handleChange}>
                                <option value = ''> select Theme </option>
                                {
                                themes.map((item , ind) =>{
                                    return (
                                    <option value = {item.name} key={ind}> {item.name}</option>
                                    )
                                })
                                }
                            </select>
                        </Form.Group>
                        {errors.theme && <span><p style={{"color":"red"}}>{errors.theme}</p></span>}
                        </Col>
                    }                       
                    {fields.theme !=='' && subThemes.length > 0 &&
                      <Col sm={12} md={6}>
                        <Form.Group controlId="formBasicName">
                            <Form.Label> Sub-Theme</Form.Label>
                            <select name = "subTheme" className='form-control' onChange = {this.handleChange}>
                              
                              <option> select Sub-Theme </option>

                              {subThemes.length > 0 ? 
                              subThemes.map((item , idx)=>{
                                return (
                                  <option value = {item.name}> {item.name} </option>
                                )
                              })
                              : null
                              
                            }
                            </select>
                        </Form.Group>
                        {errors.subTheme && <span><p style={{"color":"red"}}>{errors.subTheme}</p></span>}
                      </Col>
                    }
                    <Col sm={12} md={6}>
                      <Form.Group controlId="formBasicName">
                        <Form.Label>  Test Number  </Form.Label>
                         <Form.Control name = "test"  type ="number" value = {fields.test} onChange = {this.handleChange}/>
                      </Form.Group>
                      {errors.test && <span><p style={{"color":"red"}}>{errors.test}</p></span>}
                    </Col>
                    <Col sm={12} md={6}>
                      <Form.Group controlId="formBasicName">
                          <Form.Label> Stage Number </Form.Label>
                          <select name = "stage" className='form-control' onChange = {this.handleChange}>
                            <option value = ''> select Stage  </option>
                            { Stages.map((item , ind)=>{
                                return (
                                  <option value = {item.stageName} > {item.stageName} </option>
                                )

                            })}
                          </select>
                      </Form.Group>
                      {errors.stage && <span><p style={{"color":"red"}}>{errors.stage}</p></span>}
                    </Col>
                    {/* {fields.stage !== '' && fields.stage !== null &&
                      <Col sm={12} md={6}>
                        <Form.Group controlId="formBasicName">
                            <Form.Label> Sub-Stage</Form.Label>
                            <select name = "subStage" className='form-control' onChange={this.handleChange}>
                              <option value = '' > select Sub-Stage  </option>
                             {subStages.length > 0 ? 
                              subStages.map((item , ind) =>{
                                return (
                                  <option value = {item}> {item} </option>
                                )
                              })
                              : null
                            }
                            </select>
                        </Form.Group>
                        {errors.subStage && <span><p style={{"color":"red"}}>{errors.subStage}</p></span>}
                      </Col>
                    } */}
                     <Col sm={12} md={6}>
                      <Form.Group controlId="formBasicName">
                          <Form.Label> PO Number </Form.Label>
                          <Form.Control name = "poNumber" value = {fields.poNumber} onChange = {this.handleChange}/>
                      </Form.Group>
                      {errors.poNumber && <span><p style={{"color":"red"}}>{errors.poNumber}</p></span>}
                    </Col>
                    <Col sm={12}>
                      <Button type = "submit" >{this.state.loading ? 'Ganrating...' : 'Genrate'}</Button>
                    </Col>
                  </Row>
                </Form>
                </div>

                <Modal 
                    open={this.state.showModel} 
                    onClose={()=>this.closeModel()}
                    
                    styles={{
                        modal: {
                            animation: `${
                                this.state.openTicketDetailModel ? 'customEnterAnimation' : 'customLeaveAnimation'
                            } 7ms`,
                        },
                    }} center
                >     
                    { this.showFileNames() }  
                       
                </Modal>
            </>
            
        )
    }


}

const mapStateToProps = state => {

    const { script , theme } = state

    return {
        themes: theme.getThemes,
        videoFilename : script.addFilename
       
    }
}


const dispatchActions = { getThemes , createVideoFilename } ;


export default connect(mapStateToProps, dispatchActions) (FileNameGrenrator) ;




