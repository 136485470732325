import { 
    USER_LOGIN, 
    USER_LOGOUT, 
    LIST_USERS, 
    USER_FORGOT_PASSWORD,
    DELETE_USER, 
    USER_ROLES,
    CHANGE_USER_ROLES,
    COUNT_USERS,
    UPDATE_USER,
    GET_USER, 
    UPDATE_PROFILE
} from './types';
import { apiRequest, checkTokenExpire, postImageAndFile } from './fetchActions';
import authHeader from '../helpers/auth-header';
import { toast } from "react-toastify";
toast.configure()


export const userLogin = data => async dispatch => {

    // console.log("data" , data )
    try {
        const requestOptions = {
            method: 'POST',
            headers: { "Content-Type": "application/json; charset=utf-8" },
            body: JSON.stringify(data)
        }
        const user = await apiRequest("/user/authenticate", requestOptions);
        // console.log("user" , user)
        if(user.success  && user.token){
            localStorage.setItem('scriptDBToken_admin', JSON.stringify(user.token));
        }
        await dispatch({ type: USER_LOGIN, payload: user })
    } catch (error) {
        toast.dismiss()
        toast.error('Something Went Wrong', { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true })
    }
}


export const logout = () => async dispatch => {
    // remove user from local storage to log user out
    localStorage.removeItem('scriptDBToken_admin');
    dispatch({ type:USER_LOGOUT })
    window.location.replace('/login')
}



export const resetPassword = ( data ) => async dispatch => {
    try {
        const requestOptions = {
            method: 'PATCH',
            headers: { 
                "Content-Type": "application/json; charset=utf-8",
            },
            body: JSON.stringify( data )
        }
        const result = await apiRequest(`/reset-password`, requestOptions);
        await dispatch({ type: USER_FORGOT_PASSWORD, payload: result })
    } catch (error) {
        toast.dismiss()
        toast.error('Something Went Wrong', { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true })
    }
}

export const listUsers = () => async dispatch => {
    try {
        const requestOptions = {
            method: 'GET',
            headers: { 
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": authHeader()
            },
        }
        const result = await apiRequest(`/users?searchAdmin=`, requestOptions);
        checkTokenExpire(result)
        await dispatch({ type: LIST_USERS, payload: result })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true })
        }
    }
}

export const deleteUser = (id) => async dispatch => {
    try {
        const requestOptions = {
            method: 'DELETE',
            headers: { 
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": authHeader()
            },
        }
        const result = await apiRequest(`/user/${id}`, requestOptions);
        checkTokenExpire(result)
        await dispatch({ type: DELETE_USER, payload: result })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true })
        }
    }
}

export const userRoles = () => async dispatch => {
    try {
        const requestOptions = {
            method: 'GET',
            headers: { 
                "Content-Type": "application/json; charset=utf-8",
            },
        }
        const result = await apiRequest(`/user/roles`, requestOptions);
        checkTokenExpire(result)
        await dispatch({ type: USER_ROLES, payload: result })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true })
        }
    }
}

export const changeUserRole = (data) => async dispatch => {
    try {
        const requestOptions = {
            method: 'PATCH',
            headers: { 
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": authHeader()
            },
            body: JSON.stringify( data )
        }
        const result = await apiRequest(`/user/role`, requestOptions);
        checkTokenExpire(result)
        await dispatch({ type: CHANGE_USER_ROLES, payload: result })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true })
        }
    }
}

export const countUsers = (days) => async dispatch => {
    try {
        const requestOptions = {
            method: 'GET',
            headers: { 
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": authHeader()
            },
        }
        const result = await apiRequest(`/user/count/${days}/days`, requestOptions);
        checkTokenExpire(result)
        await dispatch({ type: COUNT_USERS, payload: result })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true })
        }
    }
}

export const updateUser = (id, data) => async dispatch => {
    try {
        const requestOptions = {
            method: 'PATCH',
            headers: { 
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": authHeader()
            },
            body:JSON.stringify(data)
        }
        const result = await apiRequest(`/user/${id}`, requestOptions);
        checkTokenExpire(result)
        await dispatch({ type: UPDATE_USER, payload: result })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true })
        }
    }
}


export const getUser = (id) => async dispatch => {
    try{
        const requestOptions = {
            method: 'GET',
            headers: { 
                "Content-Type": "application/json; charset=utf-8",
                'Authorization': authHeader()
            },
        }
        const result = await apiRequest( `/user/${id}`, requestOptions );
        checkTokenExpire(result);
        await dispatch({ type: GET_USER, payload: result})
    }catch(error){
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        }
    }
}

export const updateProfile = (formData, userId) => async dispatch => {
    try{
        const result = await postImageAndFile(`/user/${userId}`, formData );
        checkTokenExpire(result);
        await dispatch({ type: UPDATE_PROFILE, payload: result})
    }catch(error){
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong, Please try again later', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        }
    }
}

export const socialLogin = data => async dispatch => {
    try {
        const requestOptions = {
            method: 'POST',
            headers: { "Content-Type": "application/json; charset=utf-8" },
            body: JSON.stringify(data)
        }
        const user = await apiRequest("/user/ssoLogin", requestOptions);
        if(user.success  && user.token){
            localStorage.setItem('scriptDBToken_admin', JSON.stringify(user.token));
        }
        await dispatch({ type: USER_LOGIN, payload: user })
    } catch (error) {
        toast.dismiss()
        toast.error('Something Went Wrong', { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true })
    }
}
