import { 
    LIST_THEMES,
    DELETE_THEMES,
    UPDATE_THEMES,
    ADD_THEMES
 } from "../actions/types";
 
 const initialState = { getThemes:'', delTheme:'', updateTheme:'', addedTheme:''}
 
 export default ( state = initialState, action ) => {
     switch (action.type) {
         case LIST_THEMES:
             return {
                 ...state,
                 getThemes: action.payload
             }
         case DELETE_THEMES:
             return {
                 ...state,
                 delTheme:action.payload
             }
         case UPDATE_THEMES:
             return {
                 ...state,
                 updateTheme: action.payload
             }
         case ADD_THEMES:
             return {
                 ...state,
                 addedTheme:action.payload
             }
         default: return state;
     }
 }