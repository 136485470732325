import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { getFilenamesList} from '../../actions/scriptActions';
import { Table, Spinner } from'react-bootstrap';
import moment from 'moment';




class  ListFileNames extends Component {
    constructor(props) {
        super(props);
        this.state= {
            videoFilenamesList:[],
            loading : false , 

        }
    }


    componentDidMount = async () => {
        await this.handleFetchFilenames();
       
    }

    handleFetchFilenames = async () =>{

        this.setState({loading : true})
        await this.props.getFilenamesList();
        // console.log("VideoFilenames" , this.props.VideoFilenames);
        if (this.props.VideoFilenames.success) {
          this.setState({loading : false})
            this.setState({
                videoFilenamesList: this.props.VideoFilenames.data,
               
            })
        } else {
            this.setState({loading : false })
            toast.dismiss()
            toast.error(this.props.VideoFilenames.message, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true })
        }

    }

    render(){
        const {loading , videoFilenamesList} = this.state ; 
        return(
            <div>
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="./">Dashboard</a></li>
                        <li className="breadcrumb-item">Users</li>
                        <li className="breadcrumb-item active" aria-current="page"> List Video Filename </li>
                    </ol>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card mb-4">
                            <div className="card-header px-3 d-lg-flex flex-row align-items-center justify-content-between">
                                <h6 className="m-0 mb-3 mb-lg-0"><strong>Video Filename List</strong></h6>
                            </div>

                            {loading 
                            ?
                            <div className="text-center mt-3 mb-3"><Spinner as="span" animation="border" size="sm" role="status"/></div>
                            :
                            (videoFilenamesList && videoFilenamesList.length > 0 ) ?
                             <Table responsive>
                                <thead>
                                    <tr>
                                        <th>S.No.</th>
                                        <th>Filenames</th>
                                        <th> Campaign Name</th>
                                        <th>Genrated By </th>
                                        <th>Genrated Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {videoFilenamesList.map((item , idx)=>{
                                        return (
                                            <tr key={idx}>
                                                <th scope="row"> {idx + 1 } </th>
                                                <td>
                                                    <li>{item.videoFilename.fileNameFirst}</li>
                                                    <li>{item.videoFilename.fileNameSecond}</li>
                                                    <li>{item.videoFilename.fileNameThird}</li>
                                                </td>
                                                <td>{item.campaignName}</td>
                                                <td>{item.genratedBy.fullname}</td>
                                                <td>{moment(item.createdAt).format('L')}</td>
                                            </tr>
                                        )
                                    })}

                                </tbody>
                             </Table>
                              :
                              <p className="mt-3 mb-3 text-center" style={{textTransform:'uppercase'}}>  Nothing to show </p>
                            }
                        </div>
                    </div>    
                </div>    
            </div>
        )
    }


}



const mapStateToProps = state => {

    const { script} = state

    return {
        
        VideoFilenames : script.VideoFilenames
       
    }
}


const dispatchActions = {getFilenamesList } ;


export default connect(mapStateToProps, dispatchActions)(ListFileNames) ;

