import { 
    LIST_VIDEOS,
    DELETE_VIDEO,
    UPDATE_VIDEO
 } from "../actions/types";
 
 const initialState = { getVideos:'', delVideo:'', updateVideo:''}
 
 export default ( state = initialState, action ) => {
     switch (action.type) {
        case LIST_VIDEOS:
            return {
                ...state,
                getVideos: action.payload
            }
        case DELETE_VIDEO:
            return {
                ...state,
                delVideo:action.payload
            }
        case UPDATE_VIDEO:
            return {
                ...state,
                updateVideo:action.payload
            }
         default: return state;
     }
 }