export const Stages = [
    { "stageName" : "stage1" , "subStages": ["Stage 1A ", "Stage 1B", "Stage 1C" ]},
    { "stageName" : "stage2" , "subStages": ["Stage 2A ", "Stage 2B", "Stage 2C" ]},
    { "stageName" : "stage3" , "subStages": ["Stage 3A ", "Stage 3C", "Stage 3C" ]}

] ;

export const countryLanguageData = [
    {"countryName" : " Afghanistan (AF) " , "countryCode" : " AF " , "language":[{"languageName" : " (DA) Dari " , "languageCode" : " DA "}, {"languageName" : " (PA) Pashto " , "languageCode" : " PA"}]},
    {"countryName" : " Bangladesh (BD)  " , "countryCode" : " BD " , "language":[{"languageName" : " (BA) Bangladeshi " , "languageCode" : " BA "}]},
    {"countryName" : " Cambodia (KH)   " , "countryCode" : " KH " , "language":[{"languageName" : " (KH) Khmer " , "languageCode" : " KH "}]},
    {"countryName" : " India (IN)   " , "countryCode" : " IN " , "language":[{"languageName" : " (BE) Bengali " , "languageCode" : " BE "},{"languageName" : " (HI) Hindi " , "languageCode" : " HI "},{"languageName" : " (PU) Punjabi " , "languageCode" : " PU "}]},
    {"countryName" : " Indonesia (ID)  " , "countryCode" : " ID " , "language":[{"languageName" : " (ID) Indonesian " , "languageCode" : " ID "}]},
    {"countryName" : " Japan (JP)  " , "countryCode" : " JP " , "language":[{"languageName" : " (JP) Japanese " , "languageCode" : " JP "}]},
    {"countryName" : " Laos (LA)  " , "countryCode" : " LA " , "language":[{"languageName" : " ((LA) Lao " , "languageCode" : " LA "}]},
    {"countryName" : " Mongolia (MN)  " , "countryCode" : " MN " , "language":[{"languageName" : " (MN) Mongolian  " , "languageCode" : " MN "}]},
    {"countryName" : " Myanmar (MM)  " , "countryCode" : " MM " , "language":[{"languageName" : " (BM) Burmese " , "languageCode" : " BM "}]},
    {"countryName" : " Nepal (NE) " , "countryCode" : " NE " , "language":[{"languageName" : " (NE) Nepali " , "languageCode" : " NE "}]},
    {"countryName" : " Philippines (PH)  " , "countryCode" : " PH " , "language":[{"languageName" : " (FP) Filipino " , "languageCode" : " FP "},{"languageName" : " (TA) Tagalog " , "languageCode" : " TA "}]},
    {"countryName" : " Thailand (TH)  " , "countryCode" : " TH " , "language":[{"languageName" : " (TH) Thai " , "languageCode" : " TH "}]},
    {"countryName" : " Vietnam (VN)  " , "countryCode" : " VN " , "language":[{"languageName" : " (VN) Vietnamese " , "languageCode" : " VN "}]},
    {"countryName" : " Pakistan (PK)  " , "countryCode" : " PK " , "language":[{"languageName" : " (UR) Urdu " , "languageCode" : " UR "},{"languageName" : " (URs) Urdu Script " , "languageCode" : " URs"}]},
    {"countryName" : " Sri Lanka (SL)  " , "countryCode" : " SL " , "language":[{"languageName" : " (SI)Sinhalese  " , "languageCode" : " SI "}]},
    {"countryName" : " United Kingdom (UK)" , "countryCode":" UK " , "language":[{"languageName" : "(EN) English " , "languageCode" : " EN "}]},
    {"countryName" : " Maldives (MV)  " ,  "countryCode":"MV" , "language":[{"languageName":"(DV) Divehi" , "languageCode":"DV"}]},
    {"countryName" : " Ukraine (UA)" , "countryCode":" UA " , "language":[{"languageName" : "(UKr)Ukrainian" , "languageCode":"UKr"}] },
    {"countryName" : " North Africa" , "countryCode":" North Africa" , "language":[{"languageName":"(EN) English" , "languageCode":"EN"}]},
    {"countryName" : " Malaysia (MY)", "countryCode":" MY " , "language" : [{"languageName":"(ms)Malay", "languageCode":"ms" }]},
    {"countryName" : " Africa" , "countryCode":"Africa" , "language":[{"languageName":"(EN) English" , "languageCode":"EN"}] },
        
];
 

export const faceBookPageList = [
    {"countryName" : " Afghanistan (AF) " , "countryCode" : " AF " ,  "pages":["د نړۍ هیلهHope of the world" , "حق لار" , "امید برای جهان" , "راه حق"]},
    {"countryName" : " Bangladesh (BD)  " , "countryCode" : " BD " , "pages":["Jeebon Jol" , "Notun Jeeebon" , "Notun Jol"]},
    {"countryName" : " Cambodia (KH)   " , "countryCode" : " KH " ,  "pages":["មិត្តសម្លាញ់ពិត"]},
    {"countryName" : " India (IN)   " , "countryCode" : " IN " , "pages":[""]},
    {"countryName" : " Indonesia (ID)  " ,"countryCode" : " ID " , "pages":["Hidayah Hidup" , "Sahabat Sejati"]},
    {"countryName" : " Japan (JP)  " , "countryCode" : " JP " , "pages" :["Genki Life Japan"]},
    {"countryName" : " Laos (LA)  " , "countryCode" : " LA " , "pages" :["ຄວາມຫວັງທີ່ສວຍງາມ"]},
    {"countryName" : " Maldives (MV) " , "countryCode":"MV" , "pages" : ["Naya Jiwan" , "Towards the Horizon"]},
    {"countryName" : " Mongolia (MN)  " , "countryCode" : " MN " , "pages" : ["Mongolia Happiness Project"]},
    {"countryName" : " Myanmar (MM)  " , "countryCode" : " MM " , "pages" :["Faithful Friend"]},
    {"countryName" : " Nepal (NE) " ,  "countryCode" : " NE " ,"pages" : ["आशाको सन्देश" , "जीवनको आशा" , "हाम्रो आशा"]},
    {"countryName" : " Philippines (PH)  " , "countryCode" : " PH " , "pages":["NurJannah Encounters"]},
    {"countryName" : " Thailand (TH)  " , "countryCode" : " TH " , "pages":["Real Friend - เพื่อนแท้" , "God Bless You"]},
    {"countryName" : " Vietnam (VN)  " , "countryCode" : " VN " , "pages":[]},
    {"countryName" : " Pakistan (PK)  " , "countryCode" : " PK " , "pages":["Nae Zindagi","Humari Umeed","Sachai"]},
    {"countryName" : " Sri Lanka (SL)  " , "countryCode" : " SL " , "pages":["Arunodhaya Soya" , "Balaporoththuwa" , "Sabha Margaya" , "Sabadhi Piyavara", "Vidiyalai Theadi - விடியலை தேடி"]},
    {"countryName" : " United Kingdom (UK)" , "countryCode":" UK " , "pages":["Fellow HI","Fellow"]},
    {"countryName" : " Ukraine (UA) " , "countryCode":" UA " , "pages":["Fellow Chatbot"]},
    {"countryName" : " North Africa" , "countryCode":" North Africa" , "pages":["Impacter l'Afrique du Nord"]},
    {"countryName" : " Malaysia (MY)" , "countryCode":" MY " , "pages":["Impact Malaysia"]},
    {"countryName" : " Africa" , "countryCode":"Africa" , "pages":["ELOH - Hausa" , "ELOH - Somali"]},
    
    
]