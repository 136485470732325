import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { userLogin, resetPassword, socialLogin } from '../../actions/userActions';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import swal from 'sweetalert';

import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "../../authConfig";

class Login extends Component {
    constructor() {
        super();

        this.state = {
            fields: {
                email: '',
                password: '',
            },
            errors: {},
            remember: false,
            disable: false,
            resetPassword: false
        }

        this.PublicClientApplication = new PublicClientApplication(msalConfig);
    }

    componentDidMount() {
        if (localStorage.getItem('scriptDBToken_admin')) {
            this.props.history.push('/')
        }
        this.PublicClientApplication.handleRedirectPromise().then(async (tokenResponse) => {
            if (tokenResponse !== null) {
                const name = tokenResponse.account.username.split(/[^A-Za-z]/)
                // var arr = tokenResponse.account.username.split('@');
                var obj = {
                    email: tokenResponse.account.username,
                    fullname: tokenResponse.account.name || name[0],
                    uniqueId: tokenResponse.uniqueId,
                    signupType: 'Azure',
                    // role:"5fc8902ba1a30c5d841bd044",
                    emailVerification: "verified"
                }
                await this.props.socialLogin(obj);
                if (this.props.user.success) {
                    setTimeout(() => {
                        window.location.replace('/')
                    }, 800)
                    toast.dismiss()
                    toast.success(this.props.user.message, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true });

                } else {
                    toast.dismiss()
                    toast.error(this.props.user.message, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true });
                }

            }
        }).catch((error) => {
            toast.dismiss()
            toast.error('Oops, Please try again later', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true });
        });
    }

    handleChange = (field, e) => {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });
    }

    handleValidation = () => {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        if (!fields["email"]) {
            formIsValid = false;
            errors["email"] = "Can't be blank";
        }

        if (!fields["password"]) {
            formIsValid = false;
            errors["password"] = "Can't be blank";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        if (this.handleValidation()) {
            this.setState({ disable: true });
            await this.props.userLogin(this.state.fields)
            // console.log("propssss" , this.props.user)
            if (this.props.user.success) {

                
                this.setState({ disable: false })
                setTimeout(() => {
                    window.location.replace('/')
                }, 800)
                toast.dismiss()
                toast.success(this.props.user.message, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true });
            } else {
                this.setState({ disable: false })
                toast.dismiss()
                toast.error(this.props.user.message, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true });
            }
        } else {
            this.setState({ disable: false });
        }
    }

    handleResetPassword = async (e) => {
        e.preventDefault();
        this.setState({ disable: true });
        let data = {
            email: this.state.fields['email']
        }
        await this.props.resetPassword(data)
        if (this.props.user.success) {
            this.setState({ disable: false, resetPassword: false })

            swal(this.props.user.message, { icon: "success" });
        } else {
            this.setState({ disable: false })

            swal(this.props.user.message, { icon: "error" });
        }
    }

    ssoLogin = async () => {
        try {
            await this.PublicClientApplication
                .loginRedirect({
                    ...msalConfig.auth,
                    scopes: ["user.read"],
                })
        } catch (err) {
            toast.dismiss()
            toast.error('Please try again, later', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true });
        }
    }


    render() {
        const { resetPassword } = this.state
        if (resetPassword) {
            return (
                <div className="container-login">
                    <div className="row justify-content-center">
                        <div className="col-xl-10 col-lg-12 col-md-9">
                            <div className="card shadow-sm my-5">
                                <div className="card-body p-0">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="login-form">
                                                <div className="text-center">
                                                    <h1 className="h4 text-gray-900 mb-4"> <strong> Welcome To CV Global Admin </strong></h1>
                                                    <hr />
                                                    <h5> <strong> Reset Your Password </strong> </h5>
                                                </div>
                                                <form className="user" onSubmit={this.handleResetPassword}>
                                                    <div className="form-group">
                                                        <input type="text" className="form-control" id="exampleInputEmail" aria-describedby="emailHelp"
                                                            placeholder="Enter email" refs="email" onChange={(e) => this.handleChange("email", e)} value={this.state.fields["email"]} />
                                                        <span style={{ color: "red", fontSize: '12px' }}>{this.state.errors["email"]}</span>
                                                    </div>
                                                    <div className="text-right">
                                                        <span className="font-weight-bold small" style={{ cursor: 'pointer' }} onClick={() => this.setState({ resetPassword: false })}> Click here to Login</span>
                                                    </div>
                                                    <div className="form-group text-center">
                                                        <Button type="submit" className="btn btn-primary btn-block" >{this.state.disable ? 'Wait...' : 'Send'}</Button>
                                                    </div>

                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="container-login">
                    <div className="row justify-content-center">
                        <div className="col-xl-10 col-lg-12 col-md-9">
                            <div className="card shadow-sm my-5">
                                <div className="card-body p-0">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="login-form">
                                                <div className="text-center">
                                                    <h1 className="h4 text-gray-900 mb-4"> <strong> Welcome To CV Global Admin </strong></h1>
                                                    <hr />
                                                    <h5> <strong> Admin Login </strong> </h5>
                                                </div>
                                                <form className="user" onSubmit={this.handleSubmit}>
                                                    <div className="form-group">
                                                        <input type="text" className="form-control" id="exampleInputEmail" aria-describedby="emailHelp"
                                                            placeholder="Enter Email" refs="email" onChange={(e) => this.handleChange("email", e)} value={this.state.fields["email"] || ''} />
                                                        <span style={{ color: "red", fontSize: '12px' }}>{this.state.errors["email"]}</span>
                                                    </div>
                                                    <div className="form-group">
                                                        <input type="password" className="form-control" id="exampleInputPassword" placeholder="Password" refs="password" onChange={(e) => this.handleChange("password", e)} value={this.state.fields["password"] || ''} />
                                                        <span style={{ color: "red", fontSize: '12px' }}>{this.state.errors["password"]}</span>
                                                    </div>
                                                    <div className="form-group text-center">
                                                        <Button type="submit" className="btn btn-primary btn-block" >{this.state.disable ? 'Logging...' : 'Login'}</Button>
                                                    </div>
                                                    <p className="m-0 mt-2 text-center" style={{ color: '#000' }}>
                                                        <span style={{ cursor: 'pointer', color: "#007bff" }} onClick={() => this.ssoLogin()}>
                                                            SSO Login
                                                        </span>
                                                    </p>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

const mapStateToProps = state => {
    const { user } = state
    return { user: user.user }
}
const dispatchState = { userLogin, resetPassword, socialLogin }

export default connect(mapStateToProps, dispatchState)(Login);