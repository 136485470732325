import React, { Component } from 'react';
import { listUsers, deleteUser, userRoles, changeUserRole, updateUser } from '../../actions/userActions';
import { Table, Spinner, Modal, Form, Row, Col, Button } from'react-bootstrap';
import swal from 'sweetalert';
import * as check from '../../helpers/validation';
import ReactPaginate from 'react-paginate';
import { connect } from 'react-redux';
import { toast } from'react-toastify';
toast.configure()

class ListUsers extends Component {
    constructor(props){
        super(props);
        this.state = {
            users:[],
            backupUsers:[],
            loader: false,
            showEditModal: false,
            user:{},
            roles:[],
            page:0,
            perPage:10,
            searchInput:'',
            filterUser:'',
            updateData: {},
            refreshLoading: false,

            errors:{},
            isFormValid: false,
            updateLoader: false,
        }
    }

    async componentDidMount(){
        this.activeLoader(true)
        await this.getAllUsers()
    }


    /**
      @function getAllusers is used to fetch all registered users 
     */ 

    activeLoader = (value) => {
        this.setState({loader: value})
    }

    getAllUsers = async () => {
        await this.props.listUsers();
        if(this.props.users.response){
            this.activeLoader(false)
            this.setState({
                users: this.props.users.users,
                backupUsers: this.props.users.users
            })
        }else{
            this.activeLoader(false)
        }
    }

    /**
     * @function refreshData is used to sync data
     */
    refreshData = async () => {
        this.setState({ refreshLoading: true })
        await this.getAllUsers()
        this.setState({ 
            refreshLoading: false, 
            page:0,
            perPage:10,
            searchInput:'',
            filterUser:'', 
        })
    }


    /**
     * @function onDeleteUser is used to delete user permanently
     */

    onDeleteUser = async (id) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, it won't be revert",
            icon: "warning",
            buttons: ['Cancel', 'Yes'],
            dangerMode: true,
        }).then(async (willDelete) => {
            if (willDelete) {
                await this.props.deleteUser(id)
                if(this.props.deleteResponse.success){
                    this.getAllUsers();
                    swal(this.props.deleteResponse.message, {icon: "success"});
                }else{
                    swal(this.props.deleteResponse.message, {icon: "error"});
                }
            }
        });
    }


    onEditUser = async (user) => {
        this.setState({ showEditModal: true, user:user })
        await this.props.userRoles()
        if(this.props.rolesResponse.success){
            this.setState({
                roles: this.props.rolesResponse.roles
            })
        }
    }

    handleUpdateUser = async (e) => {
        e.preventDefault();
        if(JSON.stringify(this.state.updateData) === '{}' || Object.values(this.state.updateData).every(x => (x === null || x === ''))){
            toast.dismiss()
            toast.error('Please make changes and update', { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true })
        }else{
            this.setState({ updateLoader: true })
            let id = this.state.user._id;
            await this.props.updateUser(id, this.state.updateData)
            if(this.props.update.success){
                this.getAllUsers();
                toast.dismiss()
                toast.success( this.props.update.message, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true })
            }else{
                toast.dismiss()
                toast.error( this.props.update.message, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true })
            }
            this.setState({ updateLoader: false })
        }        
    }


    fieldValidation = (name) => {
        const { user } = this.state
        let errors = this.state.errors
        let isValid = true
        
        if(name === 'fullname'){
            if(!user['fullname']){
                isValid = false
                errors['fullname'] = "Can't be blank"
            }
    
            if(!check.AphabeticalsValidation(user['fullname'])){
                isValid = false
                errors['fullname'] = "Enter only letters"
            }
    
            if(user['fullname'] !== undefined ){
                if(user['fullname'].trim().length < 1){
                    isValid = false
                    errors['fullname'] = "Can't be blank"
                }
                if(user['fullname'].length <= 3){
                    isValid = false
                    errors['fullname'] = "Minimum length must be 3"
                }
            }
        }
        
        if(name === 'gender'){
            if(!user['gender']){
                isValid = false
                errors['gender'] = "Can't be blank"
            }
        }

        this.setState({ errors })
        return isValid
    }


    isFormValid = () => {
        const { user } = this.state
        let isValid = true

        if(!user['fullname']){
            isValid = false
        }

        if(!check.AphabeticalsValidation(user['fullname'])){
            isValid = false
        }

        if(user['fullname'] !== undefined ){
            if(user['fullname'].trim().length < 1){
                isValid = false
            }
            if(user['fullname'].length <= 3){
                isValid = false
            }
        }

        if(!user['gender']){
            isValid = false
        }

        return isValid
    }

    handleInputChange = (e) => {
        const {name, value} = e.target;
        let user = this.state.user;
        let errors = this.state.errors
        let data = this.state.updateData
      
        if(name === 'role'){
            let role = []
            role.push(value)
            user['role'] = role
            data['role'] = role // Its used to store only updated Values
        }else{
            user[name] = value
            data[name] = value
        }
        let isFieldValid = this.fieldValidation(name);
        let isFormValid = this.isFormValid()

        if(isFieldValid){ 
            errors[name] = ""
        }
        this.setState({ user, isFormValid, errors, updateData: data })
    }

    handleCellPhone = (e) => {
        const {name, value} = e.target
        let user = this.state.user;
        let errors = this.state.errors
        let data = this.state.updateData
        let isValid = true
        if(value !== undefined && value !== "undefined"){
            if(value.length < 1){
                isValid = false
                errors['cellphone'] = "Can't be blank"
            }
            // if(user['cellphone'].length <= 10){
            //     isValid = false
            //     errors['cellphone'] = "Minimum length must be 10"
            // }
            if(isNaN(value)){
                isValid = false
                errors['cellphone'] = 'Please Enter digits'
            }
        }
        user[name] = value
        data[name] = value
        this.setState({ user, updateData: data, isFormValid: isValid, errors })

    }

    handleSearchInput = (e) => {
        const { value } = e.target
        let users = this.state.backupUsers;
        if(value.length > 0){
            users = users.filter( user => {
                return user['fullname'].toLowerCase().match(value.toLowerCase()) || user['email'].toLowerCase().match(value.toLowerCase())
            })
            this.setState({ users })
        }else{
            this.setState({ users })
        }
        this.setState({ searchInput: value, filterUser: 'All' })
    }

    onChangeEntries = (e) => {
        const { value } = e.target
        let users = this.state.backupUsers
        // this.setState({ perPage: Number(value),  page: 0})
        this.setState({ perPage: Number(value), page: 0, searchInput: '', users})
    }

    onFilterUser = (e) => {
        const { value } = e.target
        this.setState({ filterUser: value})
        let users = this.state.backupUsers;
        if(value === 'All'){
            this.setState({users, searchInput:'', page:0})
        }else{
            users = users.filter(u =>  u.role.some((role) => role.name === value))
            this.setState({users, searchInput:'', page:0})
        }
    }   

    render(){
        const { users, loader, user, roles, searchInput, page, perPage, filterUser, refreshLoading, updateLoader, isFormValid, errors } = this.state
        const pageNumbers = Math.ceil(users.length/ perPage);
        const offset = page * perPage;
        const currentResults = users.slice(offset, offset + perPage);
        const paginate = e => this.setState({page:e.selected});
        return(
            <> 
                {user && <Modal show={this.state.showEditModal} onHide={()=> this.setState({showEditModal: false, user:{}, updateData:{}, errors:{} }, async () => this.refreshData())} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Edit
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit = {this.handleUpdateUser}>
                            <Row>
                                <Col sm={12} md={12}>
                                    <Form.Group>
                                        <Form.Label>Full Name</Form.Label>
                                        <Form.Control type="text" name="fullname" defaultValue={user['fullname'] || ''} onChange={this.handleInputChange}/>
                                        <small style={{ color:'#db1313'}}> { errors['fullname'] || '' } </small>
                                    </Form.Group>
                                </Col>
                                <Col sm={12} md={12}>
                                    <Form.Group>
                                        <Form.Label>Gender</Form.Label>
                                        <Form.Control as="select" name="gender" defaultValue={user['gender'] || ''} onChange={this.handleInputChange}>
                                            <option value="" disabled> Select Gender </option>
                                            <option value="male">Male</option>
                                            <option value="female">Female</option>
                                        </Form.Control>
                                        <small style={{ color:'#db1313'}}> { errors['gender'] || '' } </small>
                                    </Form.Group>
                                </Col>
                                <Col sm={12} md={12}>
                                    <Form.Group>
                                        <Form.Label>Cell Phone</Form.Label>
                                        <Form.Control type="text" name="cellphone" defaultValue={user['cellphone'] || ''} onChange={this.handleCellPhone}/>
                                        <small style={{ color:'#db1313'}}> { errors['cellphone'] || '' } </small>
                                    </Form.Group>
                                </Col>
                                <Col sm={12} md={12}>
                                    <Form.Group>
                                        <Form.Label>Change Role</Form.Label>
                                        <Form.Control as="select" custom name="role" defaultValue={user['role'] ? user['role'][0]._id : ''} onChange={this.handleInputChange}>
                                        {
                                            (roles && roles.length > 0) 
                                                ?
                                                <>
                                                    <option value="" disabled>Select Roles</option>
                                                    {roles.map((r, idx) => {
                                                        return (
                                                            <option value={r._id} key={idx}>{r.name}</option>
                                                        )
                                                    })}
                                                </>
                                                :
                                                <option value="" disabled>Not Found</option>
                                        }   
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>  
                            <Row className="mt-2 text-right" >
                                <Col sm={12} md={12}>
                                    <Button type="submit" role="button" variant="primary" size="sm" disabled={!isFormValid || updateLoader}> {updateLoader ? 'Wait...' : 'Submit'} </Button>
                                </Col>
                            </Row>               
                        </Form>
                    </Modal.Body>
                </Modal>}
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    {/* <h1 className="h3 mb-0 text-gray-800">List Users</h1> */}
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="./">Dashboard</a></li>
                        <li className="breadcrumb-item">Users</li>
                        <li className="breadcrumb-item active" aria-current="page"> List Users </li>
                    </ol>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card mb-4">
                            <div className="card-header px-3 d-lg-flex flex-row align-items-center justify-content-between">
                                <h6 className="m-0 mb-3 mb-lg-0"><strong>List Users</strong></h6>
                                <div className="d-md-flex flex-row align-items-center justify-content-between">
                                    <Form inline>
                                        <Form.Label className="mr-2" htmlFor="inlineFormCustomSelectPref">
                                            Search
                                        </Form.Label>
                                        <Form.Control type="text" size ="sm" placeholder="Search by Name or email" onChange={this.handleSearchInput} value={searchInput}/>
                                    </Form>  
                                    <div className="table-entries mx-lg-2 my-3 my-md-0 d-flex flex-row align-items-center justify-content-between">
                                        <span> Show </span>
                                        <Form.Control as= "select" className="mx-2" size ="sm" refs="perPpage" defaultValue={ perPage } selected={ perPage } onChange={(e) => this.onChangeEntries(e)} custom>
                                            <option value="5"> 5 Entries </option>
                                            <option value="10"> 10 Entries </option>
                                            <option value="20"> 20 Entries</option>
                                            <option value="30"> 30 Entries</option>
                                            <option value="50"> 50 Entries</option>
                                            <option value="100"> 100 Entries</option>
                                        </Form.Control>
                                      
                                    </div>
                                    <div className="filter d-flex flex-row align-items-center justify-content-between">
                                        <span className="ml-2"> Show </span>
                                        <Form.Control as= "select" className="ml-2" size ="sm" refs="filerUser" value={ filterUser } onChange={(e) => this.onFilterUser(e)} custom>
                                            <option value="All"> All </option>
                                            <option value="user"> User </option>
                                            <option value="editor"> Editor </option>
                                            <option value="admin"> Admin </option>
                                        </Form.Control>
                                    </div>
                                    <div className="table-entries mx-lg-2 my-3 my-md-0 d-flex flex-row align-items-center justify-content-between">
                                        <span onClick={() => this.refreshData()} style={{ cursor: 'pointer' }}> 
                                        { refreshLoading 
                                            ?
                                            <><Spinner animation="border" variant="dark" size="sm"/> Wait... </>
                                            :
                                            <> <i className="fa fa-refresh"></i> Refresh </>
                                        }
                                        </span>
                                    </div>                              
                                </div>
                            </div>
                            {loader 
                            ?
                            <div className="text-center mt-3 mb-3"><Spinner as="span" animation="border" size="sm" role="status"/></div>
                            :
                            (users && users.length > 0)
                                ?
                                <Table responsive>
                                    <thead>
                                        <tr>
                                            <th>S.No.</th>
                                            <th>Full Name</th>
                                            <th>Gender</th>
                                            <th>Email</th>
                                            <th>Cell Phone</th>
                                            <th>Email Verification</th>
                                            <th>Role</th>
                                            <th>Registered Date</th>
                                            <th> Action </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                           (currentResults.length ? currentResults :  users).map(( u, idx) => {
                                                return (
                                                    <tr key={idx}>
                                                        <th scope="row"> {perPage * (page) + idx + 1 } </th>
                                                        <td> {u.fullname || 'N/A'} </td>
                                                        <td> {u.gender || 'N/A'} </td>
                                                        <td> {u.email || 'N/A'} </td>
                                                        <td> {u.cellphone || 'N/A'} </td>
                                                        <td> 
                                                            {u.emailVerification === 'verified' && <span className="badge badge-success" style={{padding:'0.25rem 1rem'}}>Verified</span>}
                                                            {u.emailVerification === 'pending' && <span className="badge badge-danger" style={{padding:'0.25rem 1rem'}}>Pending</span>}
                                                        </td>
                                                        <td>
                                                            {u.role && u.role.length && u.role.map((r, ridx) => {
                                                                let badgeColor = ''
                                                                if(r.name === 'user') badgeColor = 'badge-primary';
                                                                if(r.name === 'admin') badgeColor = 'badge-success';
                                                                if(r.name === 'editor') badgeColor = 'badge-info';
                                                                return <span className={`badge ${badgeColor}`} style={{padding:'0.25rem 1rem'}} key={ridx}>{ r.name ? r.name.toUpperCase() : ''}</span>
                                                            } )}
                                                        </td>
                                                        <td>
                                                            {u.createdAt ? (new Date(u.createdAt.split('T')[0])).toLocaleString('en', {weekday: 'short', year: 'numeric', month: 'short', day: 'numeric'}) : 'N/A'}
                                                        </td>
                                                        <td>
                                                            <span className="mr-2">
                                                                <i className="fa fa-pencil" style ={{cursor:'pointer'}} onClick={() => this.onEditUser(u)}/>
                                                            </span>
                                                            <span>
                                                                <i className="fa fa-trash-o" style={{cursor:'pointer'}} onClick={() => this.onDeleteUser(u._id)}/>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                                :
                                <p className="mt-3 mb-3 text-center" style={{textTransform:'uppercase'}}>  Nothing to show </p>
                            }
                            { (users.length > 0 && pageNumbers > 1 ) &&
                                 <section className="pagination my-3">
                                    <ReactPaginate
                                        previousLabel={'<<'}
                                        nextLabel={'>>'}
                                        breakLabel={'...'}
                                        breakClassName={'break-me'}
                                        pageCount={pageNumbers}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={3}
                                        onPageChange={paginate}
                                        containerClassName={'pagination'}
                                        subContainerClassName={'pages pagination'}
                                        activeClassName={'active'}
                                    />
                                </section>
                            }
                           
                        </div>
                    </div>
                </div>
            </> 
        )
    }
}


const mapStateToProps = state => {

    const { user } = state
    return {
        users: user.listUsers,
        deleteResponse: user.deleteResponse,
        rolesResponse: user.rolesResponse,
        updateRole: user.updateRoleResponse,
        update:user.update,
    }

}

const dispatch = { listUsers, deleteUser, userRoles, changeUserRole, updateUser }
export default connect(mapStateToProps, dispatch)(ListUsers);