import { LIST_THEMES, DELETE_THEMES, UPDATE_THEMES, ADD_THEMES } from './types';
import { apiRequest, checkTokenExpire } from './fetchActions';
import authHeader from '../helpers/auth-header';
import { toast } from "react-toastify";
toast.configure()


export const addThemes = (data) => async dispatch => {
    try {
        const requestOptions = {
            method: 'POST',
            headers: { 
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": authHeader()
            },
            body:JSON.stringify(data)
        }
        const result = await apiRequest(`/theme`, requestOptions);
        checkTokenExpire(result)
        await dispatch({ type: ADD_THEMES, payload: result })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true })
        }
    }
}

export const getThemes = () => async dispatch => {
    try {
        const requestOptions = {
            method: 'GET',
            headers: { 
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": authHeader()
            },
        }
        const result = await apiRequest(`/themes`, requestOptions);
        checkTokenExpire(result)
        await dispatch({ type: LIST_THEMES, payload: result })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true })
        }
    }
}

export const deleteThemes = (themeId) => async dispatch => {
    try {
        const requestOptions = {
            method: 'DELETE',
            headers: { 
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": authHeader()
            },
        }
        const result = await apiRequest(`/theme/${themeId}`, requestOptions);
        checkTokenExpire(result)
        await dispatch({ type: DELETE_THEMES, payload: result })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true })
        }
    }
}

export const updateThemes = (themeId, data) => async dispatch => {
    try{
        const requestOptions = {
            method: 'PUT',
            headers: { 
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": authHeader()
            },
            body: JSON.stringify(data)
        }
        const result = await apiRequest(`/theme/${themeId}`, requestOptions);
        checkTokenExpire(result)
        await dispatch({ type: UPDATE_THEMES, payload: result })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true })
        }
    }
}

