import { LIST_SCRIPTS, DELETE_SCRIPT, UPDATE_SCRIPT, COUNT_SCRIPT, ADD_SCRIPT  , ADD_FILENAME ,  GET_FILENAME} from './types';
import { apiRequest, checkTokenExpire } from './fetchActions';
import authHeader from '../helpers/auth-header';
import { toast } from "react-toastify";
toast.configure()


export const addScript = (data) => async dispatch => {
    try {
        const requestOptions = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": authHeader()
            },
            body: JSON.stringify(data)
        }
        const result = await apiRequest(`/script`, requestOptions);
        checkTokenExpire(result)
        await dispatch({ type: ADD_SCRIPT, payload: result })
    } catch (error) {
        if (error && error.status === 401) {
            checkTokenExpire(error.data)
        } else {
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true })
        }
    }
}

export const getScripts = () => async dispatch => {
    try {
        const requestOptions = {
            method: 'GET',
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": authHeader()
            },
        }
        const result = await apiRequest(`/scripts`, requestOptions);
        checkTokenExpire(result)
        await dispatch({ type: LIST_SCRIPTS, payload: result })
    } catch (error) {
        if (error && error.status === 401) {
            checkTokenExpire(error.data)
        } else {
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true })
        }
    }
}

export const deleteScript = (scriptId) => async dispatch => {
    try {
        const requestOptions = {
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": authHeader()
            },
        }
        const result = await apiRequest(`/script/${scriptId}`, requestOptions);
        checkTokenExpire(result)
        await dispatch({ type: DELETE_SCRIPT, payload: result })
    } catch (error) {
        if (error && error.status === 401) {
            checkTokenExpire(error.data)
        } else {
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true })
        }
    }
}

export const updateScript = (scriptId, data) => async dispatch => {
    try {
        const requestOptions = {
            method: 'PATCH',
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": authHeader()
            },
            body: JSON.stringify(data)
        }
        const result = await apiRequest(`/script/${scriptId}`, requestOptions);
        checkTokenExpire(result)
        await dispatch({ type: UPDATE_SCRIPT, payload: result })
    } catch (error) {
        if (error && error.status === 401) {
            checkTokenExpire(error.data)
        } else {
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true })
        }
    }
}

export const countScripts = (days) => async dispatch => {
    try {
        const requestOptions = {
            method: 'GET',
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": authHeader()
            },
        }
        const result = await apiRequest(`/script/count/${days}/days`, requestOptions);
        checkTokenExpire(result)
        await dispatch({ type: COUNT_SCRIPT, payload: result })
    } catch (error) {
        if (error && error.status === 401) {
            checkTokenExpire(error.data)
        } else {
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true })
        }
    }
}


export const createVideoFilename = (data) =>async dispatch =>{
    // console.log("data", data)
    try{
        const requestOptions = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": authHeader()
            },
            body: JSON.stringify(data)
        }

        // console.log("requestOptions" , requestOptions)
        const result = await apiRequest(`/video/filename`, requestOptions);
        checkTokenExpire(result)
        await dispatch({ type: ADD_FILENAME , payload: result })
    }catch(error){
        if (error && error.status === 401) {
            checkTokenExpire(error.data)
        } else {
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true })
        }
    }
}

export const getFilenamesList = () =>async dispatch =>{
    try{
        const requestOptions = {
            method: 'GET',
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": authHeader()
            },
        }
        const result = await apiRequest(`/get/video/filename`, requestOptions);
        checkTokenExpire(result)
        await dispatch({ type: GET_FILENAME , payload: result })
    }catch(error){
        if (error && error.status === 401) {
            checkTokenExpire(error.data)
        } else {
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true })
        }

    }
}