import { 
   LIST_SCRIPTS,
//    GET_SINGLE_SCRIPT,
   DELETE_SCRIPT,
   UPDATE_SCRIPT,
   COUNT_SCRIPT,
   ADD_SCRIPT,
   ADD_FILENAME,
   GET_FILENAME
} from "../actions/types";

const initialState = { getScripts:'', delScript:'', updateScript:'', count:'' , addFilename: '' , VideoFilenames:'' }

export default ( state = initialState, action ) => {
    switch (action.type) {
        case LIST_SCRIPTS:
            return {
                ...state,
                getScripts: action.payload
            }
        case DELETE_SCRIPT:
            return {
                ...state,
                delScript:action.payload
            }
        case UPDATE_SCRIPT:
            return {
                ...state,
                updateScript: action.payload
            }
        case COUNT_SCRIPT:
            return {
                ...state,
                count: action.payload
            }
        case ADD_SCRIPT:
            return {
                ...state,
                add:action.payload
            }
        case ADD_FILENAME:
            return{
                ...state,
                addFilename:action.payload
            }    
        case GET_FILENAME:
            return{
                ...state,
                VideoFilenames:action.payload
            }    
        default: return state;
    }
}