import React, { Component } from 'react';
import Header from './Header';
import Footer from './Footer';
import Sidebar from './Sidebar';
import ROUTES, { RenderRoutes } from "../routing/Routes";


class Layout extends Component{
    constructor(props){
        super(props)

        this.state = {
            sidebarOpen: false
        }
    }

    handleViewSideBar = () => {
        this.setState({sidebarOpen: !this.state.sidebarOpen});
    }

    render(){
        return (
            <div id="wrapper">
                <Sidebar sidebarOpen = {this.state.sidebarOpen} handleViewSideBar = {this.handleViewSideBar}/>
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <Header handleViewSideBar = {this.handleViewSideBar}/>
                        <div className="container-fluid" id="container-wrapper">
                            <RenderRoutes routes={ROUTES} />
                        </div>
                    </div>
                    <Footer/>
                </div>
            </div>
        )
    }
}

export default Layout;