////// -------------------->>>  USER  <<---------------------//////

export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_FORGOT_PASSWORD = 'USER_FORGOT_PASSWORD';

export const LIST_USERS = "LIST_USERS";
export const DELETE_USER = "DELETE_USER";
export const USER_ROLES = "USER_ROLES";
export const CHANGE_USER_ROLES = "CHANGE_USER_ROLES";
export const UPDATE_USER = "UPDATE_USER";

export const COUNT_USERS = "COUNT_USERS";
export const GET_USER = "GET_USER";
export const UPDATE_PROFILE = "UPDATE_PROFILE";


//  --------------------  SCRIPT CONSTANT ------------------ //
export const LIST_SCRIPTS = "LIST_SCRIPT";
export const DELETE_SCRIPT = "DELETE_SCRIPT";
export const UPDATE_SCRIPT = "UPDATE_SCRIPT";
export const GET_SINGLE_SCRIPT = "GET_SINGLE_SCRIPT";
export const COUNT_SCRIPT = "COUNT_SCRIPT";
export const ADD_SCRIPT = "ADD_SCRIPT";

//  --------------------  VIDEO CONSTANT ------------------ //
export const ADD_VIDEO = "ADD_VIDEO";
export const LIST_VIDEOS = "LIST_VIDEOS";
export const DELETE_VIDEO = "DELETE_VIDEO";
export const UPDATE_VIDEO = "UPDATE_VIDEO";

//  --------------------  THEMES CONSTANT ------------------ //
export const LIST_THEMES = "LIST_THEMES";
export const DELETE_THEMES = "DELETE_THEMES";
export const UPDATE_THEMES = "UPDATE_THEMES";
export const ADD_THEMES = "ADD_THEMES";
export const ADD_FILENAME = "ADD_FILENAME" ;
export const GET_FILENAME = "GET_FILENAME"
