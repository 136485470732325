import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
  page: {
    paddingTop: '40',
    paddingLeft: '25',
    paddingRight: '25',
    paddingBottom: '40'
  },

  section: {
    flexDirection: 'row',
    marginBottom:'20',
  },
  sectiontitle:{
    fontSize:'18px',
    color: "#333333",
  },
  sectionSpiritual:{
    flexDirection: 'row',
    marginBottom:'20',
  },
  Spiritual:{
    fontSize:'12px',
    color: "#333333",
  },
  sectionsecond:{
    fontSize:'11px',
    color: "#333333",
    lineHeight: '1.7',
    marginBottom:'20',
  },
  sectionthird:{
    flexDirection: 'row',
    fontSize:'10px',
    color: "#333333",
    lineHeight: '1.7',
  },
  keywords:{
    marginRight:'5'
  }
});

// Create Document Component
const MyDocument = ({x}) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text style={styles.sectiontitle}>{x.title}</Text>
      </View>
      <View style={styles.sectionSpiritual}>
        <Text style={styles.Spiritual}>{x.themeId?.name ? x.themeId?.name : null}</Text>
      </View>
      <View style={styles.sectionsecond}>
        <Text>{x.script}</Text>
      </View>
      <View style={styles.sectionthird}>
        <Text style={styles.keywords}>Keywords: {x['tags'].join(' ')}</Text>
      </View>
    </Page>
  </Document>
);
export default MyDocument;