import React, { Component } from 'react';
import { getThemes, addThemes, deleteThemes, updateThemes } from '../../actions/themeActions';
// import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table, Spinner, Modal, Form, Button, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import * as check from '../../helpers/validation';
// import { jsPDF } from "jspdf";
import swal from 'sweetalert';
// import * as SwalReact from "@sweetalert/with-react";

import { toast } from 'react-toastify';
// import { ENV } from '../../env';
toast.configure()

class ListThemes extends Component {
    constructor(props) {
        super(props)

        this.state = {
            themes: [],
            themeDetail: {},
            errors: {},
            backupThemes: [],
            loader: false,
            updateLoader: false,
            page: 0,
            perPage: 10,
            searchInput: '',

            editThemeModal: false,
            isFormValid: false,
            submitLoader: false,

            refreshLoading: false,

            showUserModal: false,
            uploadedUser: {},

            showAddThemeModal: false,
            addThemeLoader: false,
            addThemeData: {
                sub_themes: [],
                theme: ''
            },
            isAddThemeFormValid: false,
        }
    }

    componentDidMount = async () => {
        this.handleLoader('loader', true)
        await this.handleFetchThemes();
    }

    handleLoader = (name, value) => { this.setState({ [name]: value }) }


    handleFetchThemes = async () => {
        await this.props.getThemes();

        // console.log("themes" , this.props.themes)
        if (this.props.themes.response) {
            this.handleLoader('loader', false)
            this.setState({
                themes: this.props.themes.themes,
                backupThemes: this.props.themes.themes
            })
        } else {
            this.handleLoader('loader', false)
            toast.dismiss()
            toast.error(this.props.themes.message, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true })
        }
    }

    refreshData = async () => {
        this.setState({ refreshLoading: true })
        await this.handleFetchThemes()
        this.setState({ refreshLoading: false })
    }


    handleSearchInput = (e) => {
        const { value } = e.target
        let themes = this.state.backupThemes;
        if (value.length > 0) {
            themes = themes.filter(script => {
                if (script['name'] !== undefined && script['subthemes'] !== undefined) {
                    return script['name'].toLowerCase().match(value.toLowerCase()) || script['subthemes'].find(e => e.name.toLowerCase().match(value.toLowerCase()))
                }
            })
            this.setState({ themes })
        } else {
            this.setState({ themes })
        }
        this.setState({ searchInput: value })
    }



    onChangeEntries = (e) => {
        const { value } = e.target
        let scripts = this.state.backupScripts
        this.setState({ perPage: Number(value), page: 0, searchInput: '', scripts })
    }

    subthemeObjectConvert = (tag) => {
        var convert = tag.map(lang => this.capitalizeFirstLetter(lang.name));
        const convertt = convert.join(", ")
        return convertt.toString();
    }

    capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    handleInputAddTheme = (e) => {
        const { value, name } = e.target
        let addThemeData = this.state['addThemeData']
        let errors = this.state['errors']
        addThemeData[name] = this.capitalizeFirstLetter(value)
        this.setState({ addThemeData, errors })
    }



    handleAddSubTheme = () => {
        let { addThemeData } = this.state
        var sub_themes = addThemeData.sub_themes
        addThemeData.sub_themes = sub_themes.concat(['']);
        this.setState({ addThemeData: addThemeData });
    }


    handleRemovetheme = (idx) => () => {
        let { addThemeData } = this.state
        var sub_themes = this.state.addThemeData.sub_themes.filter((s, sidx) => idx !== sidx);
        addThemeData.sub_themes = sub_themes;
        this.setState({ addThemeData: addThemeData });
    }

    addTheme_fieldValidation = () => {
        const { addThemeData } = this.state
        let errors = this.state.errors
        let isValid = true
        if (!addThemeData['theme']) {
            isValid = false
            errors['theme'] = "Can't be blank"
        }
        if (addThemeData['theme'] !== undefined) {

            if (addThemeData['theme'].trim().length < 1) {
                isValid = false
                errors['theme'] = "Can't be blank"
            }
            if (!check.AphabeticalsValidation(addThemeData['theme'])) {
                isValid = false
                errors['theme'] = "Enter only letters"
            }
            if (addThemeData['theme'].length < 3) {
                isValid = false
                errors['theme'] = "Minimum length must be 3"
            }
            else if (addThemeData['theme'].length > 3) {
                isValid = true
                errors['theme'] = " "
            }



        }


        if (!addThemeData['sub_themes']) {
            isValid = false
            errors['sub_themes'] = "Can't be blank"
        }

        if (addThemeData['sub_themes'] && addThemeData['sub_themes'].length < 1) {
            isValid = false
            errors['sub_themes'] = "At least enter one Sub Theme"
        }
        this.setState({ errors })
        return isValid
    }


    showSubThemesList = () => {
        let {  addThemeData } = this.state

        let sub_themes = addThemeData.sub_themes;
        // if (sub_themes.length > 0) {
        //     sub_themes.forEach((sub_theme, idx) => {
        //         if (sub_theme) {
        //             isAddThemeFormValid = true;
        //         } else {
        //             isAddThemeFormValid = false
        //         }
        //     })

        // } else {
        //     isAddThemeFormValid = false
        // }

        return (
            <div>
                <div className="row justify-content-center">
                    <div className="col-sm-12 animate__animated animate__fadeInRight">
                        <div className="d-xl-flex justify-content-between">
                        {(sub_themes.length === 0) && <button type="button" onClick={this.handleAddSubTheme} className="btn btn-sm btn-success">Add Sub Theme</button>}
                            <div className="form-group justify-content-between align-items-center flex-wrap">
                                {sub_themes.map((sub_theme, idx) => (
                                    <div className={`task-list` + ""} key={idx}>
                                        <input type="text" placeholder={`Sub Theme #${idx + 1}`} name="name" value={sub_theme} className="form-control" onChange={this.handleThemeInputChange(idx)} />

                                        <button type="button" onClick={this.handleRemovetheme(idx)} className="btn btn-sm btn-danger ml-2"> <i className="fa fa-minus" aria-hidden="true"></i></button>
                                        {(sub_themes.length === idx + 1) && <button type="button" onClick={this.handleAddSubTheme} className="btn btn-sm btn-success ml-2"> <i className="fa fa-plus" aria-hidden="true"></i></button>}
                                    </div>

                                ))}
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        )
    }

    handleThemeInputChange = (idx) => (evt) => {
        let { addThemeData } = this.state
        var sub_themes = addThemeData.sub_themes.map((sub, sidx) => {
            if (idx !== sidx) {
                return sub;
            } else {
                return evt.target.value;
            }
        });

        addThemeData.sub_themes = sub_themes;

        this.setState({ addThemeData: addThemeData });
    }

    handleAddNewTheme = async (e) => {
        e.preventDefault();

        if (this.addTheme_fieldValidation()) {

            this.setState({ disable: true, addThemeLoader: true })

            await this.props.addThemes(this.state.addThemeData)
            if (this.props.addedTheme.success) {
                this.setState({ disable: true, errors: {} })
                toast.dismiss()
                toast.success(this.props.addedTheme.message, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true })
                setTimeout(() => {
                    this.handleResetAddThemeForm()
                    this.handleFetchThemes()
                }, 1200)
            } else {
                toast.dismiss()
                toast.error(this.props.addedTheme.message, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true })
            }
        }

    }

    handleResetAddThemeForm = () => {
        let showAddThemeModal = false
        let addThemeLoader = false
        let addThemeData = {
            sub_themes: [],
            theme: ''
        }
        this.setState({
            showAddThemeModal,
            addThemeLoader,
            addThemeData,
            errors: {}
        })

    }

    // ------------------------- Add Script Functions ------------------------------- //


    // ----------------------------Delete Theme funtion -----------------------------//


    handleDeleteTheme = async (id) => {

        swal({
            title: "Are you sure?",
            text: "Once deleted, it won't be revert",
            icon: "warning",
            buttons: ['Cancel', 'Yes'],
            dangerMode: true,
        })
            .then(async (willDelete) => {
                if (willDelete) {
                    await this.props.deleteThemes(id)
                    if (this.props.delTheme.success) {
                        this.handleFetchThemes();
                        swal(this.props.delTheme.message, { icon: "success" });
                    } else {
                        swal(this.props.delTheme.message, { icon: "error" });
                    }
                }
            });
    }

    // ----------------------------Delete Theme funtion -----------------------------//

    //-------------------------------Edit Theme function-----------------------------//


    handleCloseEditModal = async () => {
        this.setState({ editThemeModal: false, themeDetail: {} })
        await this.handleFetchThemes()
    }

    showEditSubThemesList = () => {
        let { themeDetail } = this.state
        let sub_themes = themeDetail.sub_themes;
        // if (sub_themes.length > 0) {
        //     sub_themes.forEach((sub_theme, idx) => {
        //         if (sub_theme) {
        //             isAddThemeFormValid = true;
        //         } else {
        //             isAddThemeFormValid = false
        //         }
        //     })

        // } else {
        //     isAddThemeFormValid = false
        // }

        return (
            <div className="row justify-content-center">
                <div className="col-sm-12 animate__animated animate__fadeInRight">
                    <div className="d-xl-flex justify-content-between">
                        {(sub_themes.length === 0) && <button type="button" onClick={this.edithandleAddSubTheme} className="btn btn-sm btn-success">Add Sub Theme</button>}
                        <div className="form-group justify-content-between align-items-center flex-wrap">
                            {sub_themes.map((sub_theme, idx) => (
                                <div className={`task-list` + ""} key={idx}>
                                    <input type="text" placeholder={`Sub Theme #${idx + 1}`} name="name" value={sub_theme.name} className="form-control" onChange={this.edithandleSubThemeInputChange(idx)} />
                                    <button type="button" onClick={this.edithandleRemoveSubTheme(idx)} className="btn btn-sm btn-danger ml-2"> <i className="fa fa-minus" aria-hidden="true"></i></button>
                                    {(sub_themes.length === idx + 1) && <button type="button" onClick={this.edithandleAddSubTheme} className="btn btn-sm btn-success ml-2"> <i className="fa fa-plus" aria-hidden="true"></i></button>}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    edithandleAddSubTheme = () => {

        let { themeDetail } = this.state

        var sub_themes = themeDetail.sub_themes

        themeDetail.sub_themes = sub_themes.concat([{ name: '' }]);

        this.setState({ themeDetail: themeDetail });
    }

    edithandleRemoveSubTheme = (idx) => () => {
        let { themeDetail } = this.state
        var sub_themes = this.state.themeDetail.sub_themes.filter((s, sidx) => idx !== sidx);
        themeDetail.sub_themes = sub_themes;

        this.setState({ themeDetail: themeDetail });
    }

    edithandleSubThemeInputChange = (idx) => (evt) => {
        let { themeDetail } = this.state
        var sub_themes = themeDetail.sub_themes.map((sub, sidx) => {
            if (idx !== sidx) {
                return sub;
            } else {
                sub.name = evt.target.value;
                return sub;
            }
        });

        themeDetail.sub_themes = sub_themes;
        this.setState({
            themeDetail: themeDetail,
            errors: {}
        })
    }

    handleThemeUpdate = async (e) => {
        e.preventDefault();
        if (this.editTheme_fieldValidation()) {
            this.setState({ disable: true, updateLoader: true })
            await this.props.updateThemes(this.state.themeDetail._id, this.state.themeDetail)
            if (this.props.updTheme.success) {
                this.setState({ disable: false, updateLoader: false, errors: {} })
                toast.dismiss()
                toast.success(this.props.updTheme.message, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true })
                setTimeout(() => {
                    this.handleCloseEditModal()
                    this.handleFetchThemes()
                }, 1200)
            } else {
                toast.dismiss()
                toast.error(this.props.updTheme.message, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true })
            }
        }
    }

    editTheme_fieldValidation = () => {
        const { themeDetail } = this.state
        let errors = this.state.errors
        let isValid = true

        if (!themeDetail['theme']) {
            isValid = false
            errors['theme'] = "Can't be blank"
        }
        if (themeDetail['theme'] !== undefined) {

            if (themeDetail['theme'].trim().length < 1) {
                isValid = false
                errors['theme'] = "Can't be blank"
            }
            if (themeDetail['theme'].length < 3) {
                isValid = false
                errors['theme'] = "Minimum length must be 3"
            }
            if (!check.AphabeticalsValidation(themeDetail['theme'])) {
                isValid = false
                errors['theme'] = "Enter only letters"
            }
        }
        if (!themeDetail['sub_themes']) {
            isValid = false
            errors['sub_themes'] = "Can't be blank"
        }
        if (themeDetail['sub_themes'] && themeDetail['sub_themes'].length < 1) {
            isValid = false
            errors['sub_themes'] = "At least enter one Sub Theme"
        }
        themeDetail['sub_themes'].forEach((sub_theme, idx) => {
            if (sub_theme.name === null || sub_theme.name === "") {
                isValid = false;
                errors['sub_themes'] = "Sub Theme can't empty"
            }
        })


        this.setState({ errors })
        return isValid
    }

    //-------------------------------Edit Theme function-----------------------------// 


    render() {
        const { themes, loader, page, perPage, searchInput, refreshLoading, showAddThemeModal, addThemeData, addThemeLoader, errors, editThemeModal, themeDetail, updateLoader } = this.state
        const pageNumbers = Math.ceil(themes.length / perPage);
        const offset = page * perPage;
        const currentResults = themes.slice(offset, offset + perPage);
        const paginate = e => this.setState({ page: e.selected });
        // var btoa = require('btoa');
        // var token = btoa((localStorage.getItem('scriptDBToken_admin')));


        return (
            <>

                {showAddThemeModal &&
                    <Modal show={showAddThemeModal} onHide={() => this.setState({ showAddThemeModal: false, errors: {} })} size="lg" aria-labelledby="contained-modal-title-vcenter" centered backdrop="static">
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                Add New Theme
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Row>
                                    <Col sm={12} md={12}>
                                        <Form.Group>
                                            <Form.Label>Theme</Form.Label>
                                            <Form.Control type="text" name="theme" placeholder="Enter Theme Ex. Love" defaultValue={addThemeData['theme'] || ''} onChange={this.handleInputAddTheme} />
                                            <small style={{ color: '#db1313' }}> {errors['theme'] || ''} </small>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12} md={12}>
                                        <Form.Group>
                                            <Form.Label>Sub Themes</Form.Label>
                                            {this.showSubThemesList()}
                                            <small style={{ color: '#db1313' }}> {errors['sub_themes'] || ''} </small>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12} className="d-sm-flex align-items-center justify-content-end">
                                        <Button size="sm" variant="primary" className="text-capitalize mr-2" disabled={addThemeLoader} onClick={(e) => this.handleAddNewTheme(e)}> {addThemeLoader ? 'wait...' : 'Submit'} </Button>
                                        <Button size="sm" variant="outline-secondary" className="text-capitalize" onClick={() => this.handleResetAddThemeForm()}> Cancel </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Modal.Body>
                    </Modal>
                }
                {themeDetail &&
                    <Modal show={editThemeModal} onHide={this.handleCloseEditModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered backdrop="static">
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                Edit
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Row>
                                    <Col sm={12} md={12}>
                                        <Form.Group>
                                            <Form.Label>Theme</Form.Label>
                                            <Form.Control type="text" name="theme" placeholder="Enter Theme Ex. Love" defaultValue={themeDetail['theme'] || ''} onChange={this.handleInput} />
                                            <small style={{ color: '#db1313' }}> {errors['theme'] || ''} </small>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12} md={12}>
                                        <Form.Group>
                                            <Form.Label>Sub Themes</Form.Label>
                                            {editThemeModal && this.showEditSubThemesList()}
                                            <small style={{ color: '#db1313' }}> {errors['sub_themes'] || ''} </small>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12} className="d-sm-flex align-items-center justify-content-end">
                                        <Button size="sm" variant="primary" className="text-capitalize mr-2" disabled={updateLoader} onClick={(e) => this.handleThemeUpdate(e)}> {updateLoader ? 'wait...' : 'Submit'} </Button>
                                        <Button size="sm" variant="outline-secondary" className="text-capitalize" onClick={() => this.handleCloseEditModal()}> Cancel </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Modal.Body>
                    </Modal>
                }
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="./">Dashboard</a></li>
                        <li className="breadcrumb-item">Themes</li>
                        <li className="breadcrumb-item active" aria-current="page"> List Themes </li>
                    </ol>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card mb-4">
                            <div className="card-header px-3 d-lg-flex flex-row align-items-center justify-content-between">
                                <h6 className="m-0 mb-3 mb-lg-0"><strong>List Themes</strong></h6>
                                <div className="d-md-flex flex-row align-items-center justify-content-between">
                                    <div className="table-entries mx-lg-2 my-3 my-md-0 d-flex flex-row align-items-center justify-content-between">
                                        <Button size="sm" variant="primary" className="text-capitalize mr-2" onClick={() => this.setState({ showAddThemeModal: true })}> Add Themes </Button>
                                    </div>
                                    <Form inline>
                                        <Form.Label className="mr-2" htmlFor="inlineFormCustomSelectPref">
                                            Search
                                        </Form.Label>
                                        <Form.Control type="text" size="sm" placeholder="Theme/Sub theme" onChange={this.handleSearchInput} value={searchInput} />
                                    </Form>
                                    <div className="table-entries mx-lg-2 my-3 my-md-0 d-flex flex-row align-items-center justify-content-between">
                                        <span> Show </span>
                                        <Form.Control as="select" className="mx-2" size="sm" refs="perPpage" defaultValue={perPage} selected={perPage} onChange={(e) => this.onChangeEntries(e)} custom>
                                            <option value="5"> 5 Entries </option>
                                            <option value="10"> 10 Entries </option>
                                            <option value="20"> 20 Entries</option>
                                            <option value="30"> 30 Entries</option>
                                            <option value="50"> 50 Entries</option>
                                            <option value="100"> 100 Entries</option>
                                        </Form.Control>
                                    </div>
                                    <div className="table-entries mx-lg-2 my-3 my-md-0 d-flex flex-row align-items-center justify-content-between">
                                        <span onClick={() => this.refreshData()} style={{ cursor: 'pointer' }}>
                                            {refreshLoading
                                                ?
                                                <><Spinner animation="border" variant="dark" size="sm" /> Wait... </>
                                                :
                                                <> <i className="fa fa-refresh"></i> Refresh </>
                                            }
                                        </span>
                                    </div>
                                </div>
                            </div>
                            {loader
                                ?
                                <div className="text-center mt-3 mb-3"><Spinner as="span" animation="border" size="sm" role="status" /></div>
                                :
                                (themes && themes.length > 0)
                                    ?
                                    <Table responsive>
                                        <thead>
                                            <tr>
                                                <th>S.No.</th>
                                                <th>Theme</th>
                                                <th>Sub Themes</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                (currentResults.length ? currentResults : themes).map((theme, idx) => {
                                                    return (
                                                        <tr key={idx}>
                                                            <th scope="row"> {perPage * (page) + idx + 1} </th>
                                                            <td> {theme.name || 'N/A'} </td>
                                                            <td> {theme.subthemes.length > 0 ? this.subthemeObjectConvert(theme.subthemes) : 'N/A'} </td>
                                                            <td style={{ display: 'flex', alignItems: 'center' }}>
                                                                <span className="mr-2">
                                                                    <OverlayTrigger
                                                                        placement="auto"
                                                                        overlay={
                                                                            <Tooltip id={`tooltip-script_edit_${idx}`}>
                                                                                Edit Theme
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <i className="fa fa-pencil" style={{ cursor: 'pointer' }} onClick={() => {
                                                                            theme.theme = theme.name;
                                                                            theme.sub_themes = theme.subthemes;
                                                                            this.setState({ themeDetail: theme, editThemeModal: true })
                                                                        }} />
                                                                    </OverlayTrigger>
                                                                </span>
                                                                <span className="mr-1">
                                                                    <OverlayTrigger

                                                                        placement="auto"
                                                                        overlay={
                                                                            <Tooltip id={`tooltip-script_del_${idx}`}>
                                                                                Delete Theme
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <i className="fa fa-trash-o" style={{ cursor: 'pointer' }} onClick={() => this.handleDeleteTheme(theme._id)} />
                                                                    </OverlayTrigger>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                    :
                                    <p className="mt-3 mb-3 text-center" style={{ textTransform: 'uppercase' }}>  Nothing to show </p>
                            }
                            {(themes.length > 0 && pageNumbers > 1) &&
                                <section className="pagination my-3">
                                    <ReactPaginate
                                        previousLabel={'<<'}
                                        nextLabel={'>>'}
                                        breakLabel={'...'}
                                        breakClassName={'break-me'}
                                        pageCount={pageNumbers}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={3}
                                        onPageChange={paginate}
                                        containerClassName={'pagination'}
                                        subContainerClassName={'pages pagination'}
                                        activeClassName={'active'}
                                    />
                                </section>
                            }
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = state => {

    const { theme } = state

    return {
        themes: theme.getThemes,
        delTheme: theme.delTheme,
        updTheme: theme.updateTheme,
        addedTheme: theme.addedTheme
    }
}

const dispatchActions = { getThemes, addThemes, deleteThemes, updateThemes }

export default connect(mapStateToProps, dispatchActions)(ListThemes);