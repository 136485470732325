import React, { Component } from 'react';
import { logout } from '../actions/userActions';
import { connect } from 'react-redux';
// import  jwt_decode  from 'jwt-decode';
import { decodedToken } from '../helpers/decode-token';
import { ENV } from '../env';


class Header extends Component{
    constructor(){
        super()

        this.state = {
            fullname: decodedToken ? decodedToken.fullname : '',
            profile: decodedToken ? decodedToken.profile : '',
        }
    }

    render(){
        return (
            <nav className="navbar navbar-expand navbar-light bg-navbar topbar mb-4 static-top">
                <button id="sidebarToggleTop" className="btn btn-link rounded-circle mr-3" onClick={() => this.props.handleViewSideBar()}>
                    <i className="fa fa-bars"></i>
                </button>
                <ul className="navbar-nav ml-auto">
                    <div className="topbar-divider d-none d-sm-block"></div>
                    <li className="nav-item dropdown no-arrow">
                        <a className="nav-link dropdown-toggle" href={void(0)} id="userDropdown" role="button" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                            <img className="img-profile rounded-circle" src={(this.state.profile) ? ENV.imageApi + '/' + this.state.profile : "assets/img/boy.png"} style={{maxWidth: "60px"}}/>
                            <span className="ml-2 d-none d-lg-inline text-white small"> {this.state.fullname ? this.state.fullname : ''} </span>
                        </a>
                        <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                            <a className="dropdown-item" href="/my-profile">
                                <i className="fas fa-user fa-sm fa-fw mr-2"></i>
                                My Profile
                            </a>
                            <div className="dropdown-divider"></div>
                            <a className="dropdown-item" data-toggle="modal" data-target="#logoutModal" href="#" onClick={()=> {
                                this.props.logout();
                                window.location.reload();
                            }}>
                                <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2"></i>
                                Logout
                            </a>
                        </div>
                    </li>
                </ul>
            </nav>
        )
    }
}


const dispatchState = { logout }

export default connect(null, dispatchState)(Header);