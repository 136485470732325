import React, { Component } from 'react';
import { countUsers } from '../../actions/userActions';
import { countScripts } from '../../actions/scriptActions';
import { connect } from 'react-redux';
import {Row, Col, Form, Spinner} from 'react-bootstrap';

class Dashboard extends Component{
    constructor(props){
        super(props)
        this.state = {
            new_user_last_days:0,
            new_script_last_days:0,
            active_user:0,
            pending_queries:0,
            days: 30, //by default

            refreshLoading: false,
        }
    }

    componentDidMount = async () => {
        this.refreshData()
    }   

    refreshData = async () => {
        this.setState({ refreshLoading: true })
        await this.new_users_count()
        await this.new_script_count()
        this.setState({ refreshLoading: false })
    }

    new_users_count = async () => {
        await this.props.countUsers(Number(this.state.days))
        if(this.props.userCount.success){
            this.setState({ new_user_last_days: this.props.userCount.count || 0})
        }
    }

    new_script_count = async () => {
        await this.props.countScripts(Number(this.state.days))
        if(this.props.scriptCount.success){
            this.setState({ new_script_last_days: this.props.scriptCount.count || 0})
        }
    }

    onChangeDays = (e) => {
        const { value } = e.target
        this.setState({ days: Number(value)}, () => {
            this.new_users_count()
            this.new_script_count()
        })
    }
    
    render(){
        const { new_user_last_days, new_script_last_days, days, refreshLoading } = this.state
        return(
            <>
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0 text-gray-800">Dashboard</h1>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item active" aria-current="page">Dashboard</li>
                    </ol>
                </div>
                <div className="card-header mb-3 px-3 d-lg-flex flex-row align-items-center justify-content-between">
                    <h6 className="m-0 mb-3 mb-lg-0"><strong></strong></h6>
                    <div className="d-md-flex flex-row align-items-center justify-content-between">
                        <div className="mx-lg-2 my-3 my-md-0 d-flex flex-row align-items-center justify-content-between">
                            <span> Since </span>
                            <Form.Control as= "select" className="mx-2" size ="sm" refs="days" defaultValue={ days } selected={ days } onChange={(e) => this.onChangeDays(e)} custom>
                                <option value="0"> Today </option>
                                <option value="1"> Yesterday </option>
                                <option value="15"> 15 Days </option>
                                <option value="30"> 30 Days</option>
                                <option value="45"> 45 Days</option>
                                <option value="60"> 60 Days</option>
                                <option value="90"> 90 Days</option>
                            </Form.Control>
                        </div> 
                        <div className="table-entries mx-lg-2 my-3 my-md-0 d-flex flex-row align-items-center justify-content-between">
                            <span onClick={() => this.refreshData()} style={{ cursor: 'pointer'}}> 
                            { refreshLoading 
                                ?
                                <><Spinner animation="border" variant="dark" size="sm"/> Wait... </>
                                :
                                <> <i className="fa fa-refresh"></i> Refresh </>
                            }
                            </span>
                        </div>
                    </div>
                </div>
                <Row className="mb-3">
                    <Col xl={3} md={6} className="mb-4">
                        <div className="card h-100">
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="text-xs font-weight-bold text-uppercase mb-1">New User</div>
                                        <div className="h3 mb-0 mr-3 font-weight-bold text-gray-800">
                                            {new_user_last_days || 0} 
                                           
                                        </div>
                                        <div className="mt-2 mb-0 text-muted text-xs">
                                           
                                            {days === 1 && <span>Since Yesterday</span>}
                                            {days === 0 && <span>Since Today</span>}
                                            {days > 1 && <span> Since last {days} days </span>}
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="fas fa-users fa-3x text-secondary"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xl={3} md={6} className="mb-4">
                        <div className="card h-100">
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="text-xs font-weight-bold text-uppercase mb-1">New Scripts</div>
                                        <div className="h3 mb-0 mr-3 font-weight-bold text-gray-800">
                                            {new_script_last_days || 0} 
                                            
                                        </div>
                                        <div className="mt-2 mb-0 text-muted text-xs">
                                            
                                            {days === 1 && <span>Since Yesterday</span>}
                                            {days === 0 && <span>Since Today</span>}
                                            {days > 1 && <span> Since last {days} days </span>}
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="fas fa-file-text fa-3x text-secondary"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </>
        )
    }
}

const mapStateToProps = state => {
    const { user, script } = state
    return {
        userCount: user.count,
        scriptCount: script.count
    }
}

const dispatchAction = { countUsers, countScripts }

export default connect(mapStateToProps, dispatchAction)(Dashboard);